
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { createPortal } from 'react-dom';
import {
   PreviewVideoVolumeButton,
   PreviewVideoFullscreenButton,
   PreviewVideoPlayer,
   PreviewVideoProgress,
} from './modules';

const PreviewVideo = ({
   previewSrc,
   showVolumeButton,
   poster,
   voiceIconRightPosition = 16,
   autoPlay = true,
   children,
   handelLoadedData,
   videoClassName = '',
   voiceButtonClassName = 'h-8 mb-3',
   videoId,
   isContentCard = false,
   loadingClass = '',
   thumbnailIsLockedForSFW,
   isLockedForSFW,
   setOpenFullScreen,
   openFullscreen,
   showFullScreenButton,
}) => {
   const [loaded, setLoaded] = useState(true);
   const [muted, setMuted] = useState(true);
   const [width, setWidth] = useState(0);
   let interval = null;
   useEffect(() => {
      return () => {
         clearInterval(interval);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onLoadedData = (e) => {
      interval = setTimeout(() => {
         setWidth(100);
      }, 10)
      interval = setTimeout(() => {
         setLoaded(false);
         if(handelLoadedData){
            handelLoadedData();
         }
      }, 710)
   }
   return (
      <>
         {
            loaded && (
               <div
                  className={ `absolute w-full h-full flex flex-col bg-non-re bg-contain bg-no-repeat bg-center ${ loadingClass }` }>
                  {
                     children && children
                  }
                  {
                     !children && (
                        <>
                           <div
                              className='absolute w-full h-full bg-no-repeat bg-cover inset-0'
                              style={ {
                                 filter: `blur(8px)`,
                                 backgroundImage: `linear-gradient(180.46deg, rgba(0, 0, 0, 0) 67.2%, rgba(0, 0, 0, 0.6) 99.3%), url(${ poster })`,
                              } }
                           />
                           <div
                              className='absolute w-full h-full bg-no-repeat bg-contain bg-center z-20'
                              style={ {
                                 backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 25.83%, rgba(0, 0, 0, 0) 80.32%, rgba(0, 0, 0, 0.4) 100% ), url(${ poster })`,
                                 filter: thumbnailIsLockedForSFW ? 'blur(32px)' : '',
                              } }
                           />
                        </>
                     )
                  }
                  <PreviewVideoProgress
                     width={ width }
                  />
               </div>

            )
         }
         <div className='h-full w-full relative'>
            {
               !openFullscreen && (
                  <PreviewVideoPlayer
                     isLockedForSFW={ isLockedForSFW }
                     muted={ muted }
                     autoPlay={ autoPlay }
                     previewSrc={ previewSrc }
                     videoClassName={ videoClassName }
                     loaded={ loaded }
                     onLoadedData={ onLoadedData }
                  />
               )
            }
            {
               !loaded && !isLockedForSFW && (showFullScreenButton || showVolumeButton) && (
                  <>
                     {
                        !isContentCard && document.getElementById(`media-layer`) && createPortal(
                           <div
                              style={ { right: voiceIconRightPosition } }
                              className={ `gap-3 absolute flex items-center justify-center bottom-0 z-[40] ${ voiceButtonClassName }` }>
                              {
                                 showFullScreenButton && (
                                    <PreviewVideoFullscreenButton
                                       openFullscreen={ openFullscreen }
                                       setOpenFullScreen={ _ => setOpenFullScreen(true) }
                                    />
                                 )
                              }
                              {
                                 showVolumeButton && (
                                    <PreviewVideoVolumeButton
                                       muted={ muted }
                                       setMuted={ setMuted }
                                    />
                                 )
                              }
                           </div>
                           ,
                           document.getElementById(`media-layer`)
                        )
                     }
                     {
                        Boolean(videoId) && document.getElementById(`content_card_mute_unmute_${ videoId }`) && createPortal(
                           <div className='gap-3 h-6 flex items-center justify-center'>
                              {
                                 showFullScreenButton && (
                                    <PreviewVideoFullscreenButton
                                       openFullscreen={ openFullscreen }
                                       setOpenFullScreen={ _ => setOpenFullScreen(true) }
                                    />
                                 )
                              }
                              {
                                 showVolumeButton && (
                                    <PreviewVideoVolumeButton
                                       muted={ muted }
                                       setMuted={ setMuted }
                                    />
                                 )

                              }
                           </div>
                           ,
                           document.getElementById(`content_card_mute_unmute_${ videoId }`)
                        )
                     }

                  </>
               )
            }
         </div>
      </>
   )
}

PreviewVideo.propTypes = {
   previewSrc: PropTypes.string,
   showVolumeButton: PropTypes.bool,
   autoPlay: PropTypes.bool,
   voiceIconRightPosition: PropTypes.number,
   voiceButtonClassName: PropTypes.string,
   poster: PropTypes.string,
   videoClassName: PropTypes.string,
   children: PropTypes.any,
   handelLoadedData: PropTypes.func,
   videoId: PropTypes.number,
   isContentCard: PropTypes.bool,
   loadingClass: PropTypes.string,
   thumbnailIsLockedForSFW: PropTypes.bool,
   isLockedForSFW: PropTypes.bool,
   setOpenFullScreen: PropTypes.func,
   openFullscreen: PropTypes.bool,
   showFullScreenButton: PropTypes.bool,
}

export default PreviewVideo

import {
   initSiteDetails,
   deleteStories,
   loginAs,
   fetchLiveStream,
   updateSiteName,
   getSiteLogo,
   logoImageUpload,
   faviconImageUpload,
   updateSettings,
   getPayoutsInit,
   connectToMyList,
   repostContent,
   unlockDemoModePublicSite,
} from 'admin/api/AuthApi';
import Auth from 'admin/utils/Auth';
import {
   siteDetailsInitStart,
   siteDetailsRefreshStart,
   siteDetailsInitCompleted,
   setViewAsModal,
   updateSiteNameStart,
   updateSiteNameCompleted,
   updateSiteNameFailed,
   updateIsOfflineModeCompleted,
   updateIsOfflineModeStart,
   updateReceivedOrdersCountAction,
   setIsLoadingConnectToMylistAction,
   connectToMylistCompleted,
} from './actions';
import {
   getPayoutsInitCompleted,
} from 'admin/state/modules/account-payout/actions';
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import Intercom from 'common/utils/Intercom';
import { addUploaders } from 'admin/state/modules/uploads/actions';
import { createOrUpdateRecordStream } from 'admin/state/modules/livestream/actions';
// import CannyUser from 'admin/utils/CannyUser';
import { isLocalhost } from 'admin/utils/Helpers';
import { initUploadsSocket } from 'common/socket';
import { initChatSocket, initOrderRequestsSocket, initUpdateEventsSocket } from 'admin/utils/socket';
import { setSocketConnectedAction } from 'admin/state/modules/chat/actions';
import { setUnseenCountAction } from 'admin/state/modules/notifications/actions';
import { fetchIndexRequest as initLiveStreamAction } from 'admin/state/modules/livestream/actions';
import { socketConnection as liveStreamSocketConnection } from 'admin/state/modules/livestream/operations';
import QueryParams from 'admin/utils/QueryParams';
import { getMyListUrl, getProtocol, toTitleCase, setCookieValue, onTranslteLanguage } from 'common/utils/utils';
import { smartlookIdentify } from 'admin/utils/smartlook';
import Convert from 'common/utils/convert-svg-to-png';
import 'admin/views/my-site/design/site-logo/font.css';
import toster from 'common/utils/toast';
import { updateOrderRequestsData } from '../store/actions';
import { updateSettingsFailed, updateSettingsRequest } from '../outgoing-notifications/actions';
import { identifyUser } from 'admin/utils/posthog';

export const siteDetailsInitOperation = () => {
   // eslint-disable-next-line consistent-return
   return async (dispatch, getState) => {
      dispatch(siteDetailsInitStart());
      if(!Auth.isTokenExists()) {
         window.skinPrimaryColor = '#231FFF'
         window.skinSecondaryColor = '#616784'
         setTimeout(() => {
            Intercom.shutDown();
            Intercom.boot();
            dispatch(siteDetailsInitCompleted({
               authUser: null,
               siteInfo: null,
            }));
         });
         const language = 'en';
         setCookieValue('googtrans', `/en/${ language }`, 1000);
         onTranslteLanguage(language);
         window.google_translte_language = language;
      } else {
         try {
            const {
               data: {
                  site_info,
                  user,
                  site_domain_details,
                  site_steps: siteSteps,
                  uploads,
                  intercom_verification_token,
                  notification_badge_count,
                  live_stream_details,
                  can_add_content,
                  received_orders_badge_count,
                  is_demo_mode,
                  account_type,
                  extended_mode,
               },
            } = await initSiteDetails();
            const cannyToken = user?.canny_token;
            if(cannyToken){
               const redirectURL = getRedirectCannyURL(cannyToken); //CANNY SSO login
               if(redirectURL) {
                  window.location.assign(redirectURL);
               }
            }
            window.document.title = site_info.site_long_name;
            // start livesession init
            if(window.smartlook && user && window.location.href.includes('dashboard.mymember.site')) {
               smartlookIdentify(user.uuid, {
                  name: site_info.site_name,
                  email: user.email,
               })
            }
            //end livesession init
            // // start livesession init
            // if(window['__ls']) {
            //    window['__ls']('init', '2548a802.07c00194', { keystrokes: false })
            //    window['__ls']('identify', { name: site_info.site_name, email: user.email })
            //    window['__ls']('newPageView')
            // }
            // //end livesession init
            window.skinPrimaryColor = '#231FFF'
            window.skinSecondaryColor = '#616784'
            let showLiveStreamBanner = false;
            if(!!live_stream_details) {
               const states = getState();
               const locationPath = states.router?.location?.pathname
               showLiveStreamBanner = !Router.route('LIVE_STREAM_STUDIO').match(locationPath);
            }
            // init live stream
            const { data } = await fetchLiveStream();
            const initData = data;
            const { ivs } = data;
            const  state = {
               isFetching: false,
               initData,
               ivs,
               inited: false,
            }

            if(site_info && initData.is_online) {
               dispatch(liveStreamSocketConnection(site_info));
            }
            dispatch(initLiveStreamAction(state))
            Intercom.shutDown();
            Intercom.boot({
               user_id: !!is_demo_mode ? null : site_info.unique_site_id.toString(),
               demo: is_demo_mode,
               user_hash: intercom_verification_token,
            });
            const intercomeAction = QueryParams.getParam('intercom-action');
            if(window.Intercom && intercomeAction){
               window.Intercom(intercomeAction);
            }
            dispatch(addUploaders(uploads));
            dispatch(setUnseenCountAction(notification_badge_count));
            // CannyUser.createCannyUser(user)
            if(user) {
               if(window.location.href.includes('dashboard.mymember.site')) {
                  identifyUser(user.uuid, {
                     name: site_info.site_name,
                     email: user.email,
                  })
               }

               window.uploadsVideoSoket = initUploadsSocket(user.site_name)
               window.adminUpdateEventsSocket = initUpdateEventsSocket(user.site_name)
               window.adminOrderRequestsSocket = initOrderRequestsSocket(user.site_name)
               window.adminUpdateEventsSocket.removeAllListeners('data-updates');
               window.adminOrderRequestsSocket.removeAllListeners('order_request_member:completed');

               window.adminOrderRequestsSocket.on('order_request_member:completed', (payload) => {
                  if(payload?.product_status_name === 'waiting') return

                  let isUpdate = false

                  if(!!Router.route('STORE_ORDER_REQUESTS').match(window?.location?.pathname)) {
                     let params = QueryParams.getFilters()?.status
                     if(!!params && !params.includes('received')) {
                        isUpdate = true
                     }
                  }

                  dispatch(updateOrderRequestsData({ ...payload, isUpdate }))
                  let receivedOrderCount = payload.received_statuses_count

                  if(receivedOrderCount !== null && typeof receivedOrderCount !== 'number') {
                     receivedOrderCount = +receivedOrderCount
                  }
                  receivedOrderCount = payload?.received_statuses_count < 0 ? 0 : payload?.received_statuses_count
                  dispatch(updateReceivedOrdersCountAction(receivedOrderCount))
               });

               window.adminUpdateEventsSocket.on('data-updates', (payload) => {
                  dispatch(handleUpdateEvents(payload))
               });

               window.uploadsVideoSoket.on('uploads.video-update', (video) => {
                  const socketEvent = new CustomEvent('content-update', {
                     detail: {
                        payload: video,
                     },
                  })
                  window.dispatchEvent(socketEvent);
               });

               // connect chat socket
               const socket = initChatSocket(user.site_name);
               socket.removeAllListeners('connect');
               socket.removeAllListeners('connect_error');
               socket.removeAllListeners('disconnect');
               socket.removeAllListeners('ready');

               socket.on('connect', (r) => {
                  socket.emit('online')
               });
               socket.on('connect_error', () => {
                  dispatch(setSocketConnectedAction(false))
               });
               socket.on('disconnect', (res) => {
                  dispatch(setSocketConnectedAction(false))
               });
               socket.on('ready', () => {
                  dispatch(setSocketConnectedAction(true))
               });
            }
            const language = user?.translte_language || 'en';
            setCookieValue('googtrans', `/en/${ language }`, 1000);
            onTranslteLanguage(language);
            window.google_translte_language = language;

            return dispatch(siteDetailsInitCompleted({
               authUser: user,
               siteInfo: {
                  ...site_info,
                  can_add_content,
               },
               siteDomainDetails: site_domain_details,
               siteSteps,
               liveStreamCurrentSessionState: {
                  isShowBanner: showLiveStreamBanner,
                  data: live_stream_details,
               },
               received_orders_badge_count,
               accountType: account_type,
               extendedMode: extended_mode,
            }));

         } catch (error) {
            // TODO check error response and fire needly action
            return dispatch(siteDetailsInitCompleted({
               authUser: null,
               siteInfo: null,
            }));
         }
      }
   };
};

function getQueryParameterByName(name) {
   const pairStrings = window.location.search.slice(1).split('&');
   const pairs = pairStrings.map(function(pair) {
      return pair.split('=');
   });
   return pairs.reduce(function(value, pair) {
      if(value) return value;
      return pair[0] === name ? decodeURIComponent(pair[1]) : null;
   }, null);
}

function getRedirectCannyURL(ssoToken) {
   const redirectURL = getQueryParameterByName('cannyRedirect');
   const companyID = getQueryParameterByName('cannyCompanyID');
   if(!redirectURL && !companyID){
      return null;
   }
   if(redirectURL.indexOf('https://') !== 0 || !companyID) {
      return null;
   }
   return 'https://canny.io/api/redirects/sso?companyID=' + companyID + '&ssoToken=' + ssoToken + '&redirect=' + redirectURL;
}

export const siteDetailsRefreshOperation = (loading = true, callBack) => {
   // eslint-disable-next-line consistent-return
   return async (dispatch) => {
      if(loading) {
         dispatch(siteDetailsRefreshStart());
      }
      try {
         const {
            data: { site_info, user, site_domain_details, site_steps: siteSteps, can_add_content },
         } = await initSiteDetails();
         if(callBack){
            callBack();
         }
         return dispatch(siteDetailsInitCompleted({
            authUser: user,
            siteInfo: {
               ...site_info,
               can_add_content,
            },
            siteDomainDetails: site_domain_details,
            siteSteps,
         }));

      } catch (error) {
         // TODO check error response and fire needly action
         return dispatch(siteDetailsInitCompleted({
            authUser: null,
            siteInfo: null,
         }));
      }
   };
}

export const deleteStore = (id) => {
   return async dispatch => {
      await deleteStories(id).then(res => {
         dispatch(push(Router.route('STORIES').getCompiledPath()))
      }).catch((err) => {
      })
   }
};

export const loginAsOperation = (data, siteUrl, pageUrl) => {
   return async dispatch => {
      let response = {}
      let url = '';
      if(isLocalhost()) {
         url = `http://localhost:3001`;
      } else {
         url = `${ getProtocol() }${ siteUrl }`;
      }
      const newWindow = window.open(`${ url }/stories?loginas=started`)
      try {
         if(data && data.id) {
            response = await loginAs({ pricing_id: data.id, type: data.type });
         } else {
            response = await loginAs({ type: data.type });
         }
         if(response && response.data && response.data.token){
            if(pageUrl) {
               newWindow.location.replace(`${ url }/${ pageUrl }?show-login-as=true&jwt-token=${ response.data.token }`);
            } else {
               newWindow.location.replace(`${ url }?show-login-as=true&jwt-token=${ response.data.token }`);
            }
         } else {
            newWindow.location.replace(`${ url }/not-found`)
         }
         dispatch(setViewAsModal(false))
      } catch (error) {
         newWindow.location.replace(`${ url }/not-found`)
      }
   }
}

export const handleUpdateEvents = (data) => {
   return async (dispatch, getState) => {
      const {
         type,
         payload,
      } = data;
      switch (type) {
         case 'livestream_video':{
            dispatch(createOrUpdateRecordStream(payload))
            break;
         }
         case 'payout_update':{
            const states = getState();
            const locationPath = states.router?.location?.pathname;
            if(Boolean(!!Router.route('MY_ACCOUNT').match(locationPath) && 'payouts' === QueryParams.getParam('activeTab'))){
               const res = await getPayoutsInit();
               dispatch(getPayoutsInitCompleted(res.data))
            }
            break;
         }
         default:
            break;
      }
   }

};
export const updateIsOfflineModeOperation = (data) => {
   return async (dispatch) => {
      dispatch(updateIsOfflineModeStart(true))
      try {
         await updateSettings(data);
         if(window.adminChatSocket){
            window.adminChatSocket.emit('offline-mode', {
               isOfflineMode: data.is_offline_mode,
            });
         }
         dispatch(updateIsOfflineModeCompleted(data));
         let tooltipText = 'Offline mode has been deactivated';
         if(data.is_offline_mode){
            tooltipText = 'Offline mode has been activated';
         }
         toster.success(tooltipText);
      } catch (error) {
         dispatch(updateIsOfflineModeStart(false))

      }
   };
}
export const updateSiteNameOperation = (newSiteName, callBack, oldSiteName) => {
   return async (dispatch) => {
      dispatch(updateSiteNameStart())
      try {
         await updateSiteName({ site_name: newSiteName });
         const siteName = toTitleCase(newSiteName);
         const logoInfo = await getSiteLogo();
         const { main_logo_data, app_icon_data, app_icon_mode } = logoInfo?.data;
         let siteSettings = {};
         const logoText = app_icon_data?.text || '';
         const logoLength = logoText.length;
         const newLogoTextValue = siteName.slice(0, logoLength);
         const oldSitenameCut = oldSiteName.slice(0, logoLength).toUpperCase();
         const newSitenameCut = newLogoTextValue.toUpperCase();
         if(oldSitenameCut === logoText.toUpperCase() && newSitenameCut !== logoText.toUpperCase()){
            if('text-logo' === app_icon_mode){
               const iconImageOptions = {
                  type: 'default',
                  fontName: app_icon_data.font_name,
                  textColor: app_icon_data.color,
                  bgColor: app_icon_data.background,
                  dx: app_icon_data.dx,
                  dy: app_icon_data.dy,
                  strokeColor: app_icon_data.stroke_color,
                  text: newLogoTextValue.charAt(0).toUpperCase() + newLogoTextValue.slice(1),
                  fontSize: app_icon_data.font_size,
               }
               const favicon = await faviconImageUpload(iconImageOptions);
               siteSettings = {
                  ...siteSettings,
                  app_icon_mode: "text-logo",
                  app_icon_data: {
                     ...app_icon_data,
                     text: newLogoTextValue.charAt(0).toUpperCase() + newLogoTextValue.slice(1),
                  },
                  app_icon: favicon?.data?.path,
               }
            } else {
               siteSettings = {
                  ...siteSettings,
                  app_icon_data: {
                     ...app_icon_data,
                     text: newLogoTextValue.charAt(0).toUpperCase() + newLogoTextValue.slice(1),
                  },
               }
            }
         }
         if('text-logo' === logoInfo?.data?.main_logo_mode){
            setDevaultIcon('main', siteName, main_logo_data, async (res) => {
               const logoImageOptions = {
                  type: 'default',
                  fontName: main_logo_data.font_name,
                  color: main_logo_data.color,
                  width: res.width,
                  text: siteName,
                  fontSize: res.fontSize,
               };
               const { data } = await logoImageUpload(logoImageOptions);
               siteSettings = {
                  ...siteSettings,
                  main_logo_mode: 'text-logo',
                  main_logo_data: {
                     ...main_logo_data,
                     logo_font_size: res.fontSize,
                     width: res.width,
                     text: siteName,
                  },
                  main_site_logo: data?.path,
               }
               await updateSettings(siteSettings);
            })
         } else {
            siteSettings = {
               ...siteSettings,
               main_logo_data: {
                  ...main_logo_data,
                  text: siteName,
               },
            }
         }
         if('text-logo' !== logoInfo?.data?.main_logo_mode){
            await updateSettings(siteSettings);
         }

         dispatch(siteDetailsRefreshOperation(false, () => {
            dispatch(updateSiteNameCompleted(siteName));
            window.document.title = siteName;
            if(callBack){
               callBack();
            }
            toster.success('Your changes have been saved');
         }));
      } catch (error) {
         if(error?.response?.status === 422 && error?.response?.data?.message) {
            toster.error(error?.response?.data?.message, {
               toastId: 'sitename_error',
            });
         }
         const errorData = error?.response?.data;
         dispatch(updateSiteNameFailed(errorData));

      }
   };
}
function setDevaultIcon(type, siteName, logoSettings, callBack){
   let svg = null;
   let settings = logoSettings;
   if('main' === type) {
      Convert.getGoogleFonts(siteName, (data) => {
         const svg = Convert.logoSvgMaket(logoSettings, siteName);
         const height = 56;
         svg.setAttribute('width', 232);
         svg.setAttribute('height', height)
         const svgElement = document.querySelector('body').appendChild(svg)
         const getfontSize = () => new Promise((resolve) => {
            return resolve(Convert.getfontSize(svgElement))
         });
         getfontSize().then(fontSize => {
            let width = 232;
            const textSvg = svgElement.querySelectorAll('text')[0];
            const textSvgWidth = textSvg.getBoundingClientRect().width;
            if(textSvgWidth + 1 <= 232){
               width = textSvgWidth + 1;
            }
            if(callBack){
               callBack({ fontSize, width })
            }
            document.querySelector('body').removeChild(svg)
         }).catch(e => {
            console.log(e)
         })
      }, [logoSettings?.font_name])
   } else {
      let defaultSettings = logoSettings
      settings = defaultSettings;
      svg = Convert.faviconSvgMaket(defaultSettings, defaultSettings.text);
      return { svg, settings }
   }
}

export function goToMylistOperation(callback) {
   return async (dispatch, getState) => {
      try {
         const state = getState();
         if('all' !== state.common.accountType) {
            dispatch(setIsLoadingConnectToMylistAction(true))
            await connectToMyList()
            dispatch(connectToMylistCompleted())
         }

         const authToken = Auth.getToken()
         const mylistUrl = getMyListUrl()

         const redirectPath = `${ mylistUrl }/dashboard?jwt-token=${ authToken }`
         window.open(redirectPath, '_blank')

         if(callback) {
            callback()
         }

      } catch (error) {
         toster.error('Something went wrong')
         dispatch(setIsLoadingConnectToMylistAction(false))
      }
   }
}

export const repostContentOperation = (slug, data, id, callBack) => {
   return async (dispatch) => {
      dispatch(updateSettingsRequest());
      try {
         const { data: date } = await repostContent(slug, id, data);

         toster.info('Content has been reposted');
         if(callBack){
            callBack('success', date);
         }
      } catch (error) {
         if(error?.response?.status === 403) {
            callBack('validationError')
            return
         }
         if(callBack){
            callBack('error');
         }
         dispatch(updateSettingsFailed(error?.response?.data));
      }
   };
}

export const checkAndUnlockDemoPublicSiteOperation = (callback) => {
   return async (dispatch, getState) => {
      try {
         const { common: { siteInfo } } = getState()
         if("demo_mode" === siteInfo.site_status) {
            await unlockDemoModePublicSite()
         }
         if(callback) {
            callback()
         }
      } catch (error) {
         console.log(error);
         toster.error('Something went wrong')
      }
   }
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImagesContent from './images';
import VideosContent from './videos';
import TabSwitcher, { Tab, TabPanel } from 'common/components/modules/tabs-switcher';
import './style.scss'
import QueryParams from 'admin/utils/QueryParams';
// import VaultContent from './all-vault';


function Vault({
   isInsideModal,
   onFilterChange,
   desktopType,
   openDeleteConfirmModal,
   pageType,
   onTabChange,
   handleAddNewVault,
   removeFile,
   onSelectCard,
   selected,
   // images
   vaultImagesData,
   isEmptyImages,
   isFetchingImages,
   isFetchingImagesByFilter,
   isNewFetchingImages,
   isEmptyImagesByFilter,
   // videos
   vaultVideosData,
   isFetchingVideos,
   isEmptyVideos,
   isFetchingVideosByFilter,
   isNewFetchingVideos,
   isDownloading,
   downloadOriginalFile,
   isEmptyVideosByFilter,
   // all vault
   showNewStyles,
   allVaultData,
   isFetchingAllVault,
   isEmptyAllVault,
   isEmptyAllVaultByFilter,
   isFetchingAllVaultByFilter,
   onFilterByType,
   isNewfetchingAllVaults,
   screenWidth,
   bundleId,
   contentType,
   onDeleteMessag,
   updateVaultMessageAction,
   updateMessageUnlockDetails,
   isMultipleSelect,
   isMultipleAttachments,
   isDemoMode,
   isMaxAttachmentCountReached,
   onClearAllFilters,
   allFilters,
   goTo,
}) {

   const [selectedCards, setSelectedCard] = useState(selected || []);
   const [selectedCardsSrc, setSelectedCardSrc] = useState([]);
   // const [filters, setFilters] = useState({
   //    name: 'Newest',
   //    value: 'date_added:desc',
   // });

   // all_vault
   // const [selectedIds, setSelectedIds] = useState(selected || [])
   // const [selectedItems, setSelectedItems] = useState([])

   useEffect(() => {
      if(selected && selected.length !== 0) {
         let data = pageType === 'video' ? vaultVideosData : vaultImagesData;
         let newData = data?.filter(i => selected.includes(i.id))
         setSelectedCardSrc(newData)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [vaultVideosData, vaultImagesData, pageType])

   useEffect(() => {
      removeQuery()
      return () => {
         removeQuery()
      }
   }, []);

   const removeQuery = () => {
      let param = QueryParams.getParam('video-uploading-error')
      if(param) {
         QueryParams.removeQueryParam('video-uploading-error')
      }
   }

   const selectCard = (isChecked, current) => {
      const { id } = current
      let newData = [];
      let newSrcData = [];
      if('video' === pageType && !isMultipleSelect){
         newData = [id]
         newSrcData = [current]
      } else {
         newData =  [...selectedCards];
         newSrcData = [...selectedCardsSrc];
         if(isChecked) {
            newData = isMaxAttachmentCountReached ? newData : [...newData, id];
            newSrcData = isMaxAttachmentCountReached ? newSrcData : [...newSrcData, current];
         } else {
            newData = newData.filter(item => item !== id);
            newSrcData = newSrcData.filter(item => item.id !== id);
         }
      }
      if(isMultipleAttachments) {
         onSelectCard([current]);
      } else {
         onSelectCard(newSrcData);
      }
      setSelectedCard(newData);
      setSelectedCardSrc(newSrcData);
   }

   // const onChangeSelected = (isChecked, item) => {
   //    let newIds = selectedIds
   //    let newCards = selectedItems
   //    if(isChecked) {
   //       newCards = newCards.filter(el => el.id !== item.id);
   //       newIds = newIds.filter(id => id !== item.id)
   //    } else {
   //       newCards = [...newCards, item]
   //       newIds = [...newIds, item.id]
   //    }
   //    setSelectedIds(newIds);
   //    setSelectedItems(newCards);
   //    onSelectCard(newCards);
   // }


   if(isInsideModal){
      switch (pageType) {
         case 'video':
            return <div className='vault-page h-full'>
               <VideosContent
                  isInsideModal={ isInsideModal }
                  data={ vaultVideosData }
                  isFetching={ isFetchingVideos }
                  isEmpty={ isEmptyVideos }
                  isFetchingByFilter={ isFetchingVideosByFilter }
                  isNewFetching={ isNewFetchingVideos }
                  desktopType={ desktopType }
                  openDeleteConfirmModal={ openDeleteConfirmModal }
                  removeFile={ removeFile }
                  selectedCards={ selectedCards }
                  selectCard={ selectCard }
                  filters={ allFilters }
                  handleAddNewVault={ handleAddNewVault }
                  pageType={ pageType }
                  isDownloading={ isDownloading }
                  downloadOriginalFile={ downloadOriginalFile }
                  contentType={ contentType }
                  screenWidth={ screenWidth }
                  onDeleteMessag={ onDeleteMessag }
                  updateVaultMessageAction={ updateVaultMessageAction }
                  updateMessageUnlockDetails={ updateMessageUnlockDetails }
                  isMultipleSelect={ isMultipleSelect }
                  isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                  onFilterChange={ onFilterChange }
                  onClearAllFilters={ onClearAllFilters }
                  isMultipleAttachments={ isMultipleAttachments }
                  isEmptyByFilter={ isEmptyVideosByFilter }
                  bundleId={ bundleId }
               />
            </div>
         // case 'all_vault':
         //    return <div className='vault-page h-full'>
         //       <VaultContent
         //          isInsideModal={ isInsideModal }
         //          data={ allVaultData }
         //          isFetchingByFilter={ isFetchingAllVaultByFilter }
         //          isFetching={ isFetchingAllVault }
         //          isEmpty={ isEmptyAllVault }
         //          isEmptyAllVaultByFilter={ isEmptyAllVaultByFilter }
         //          desktopType={ desktopType }
         //          selectedCards={ selectedIds }
         //          filters={ filters }
         //          showNewStyles={ showNewStyles }
         //          onFilterByType={ onFilterByType }
         //          onChangeSelected={ onChangeSelected }
         //          isNewfetchingAllVaults={ isNewfetchingAllVaults }
         //          selected={ selected }
         //          setSelectedItems={ setSelectedItems }
         //          onSelectCard={ onSelectCard }
         //          screenWidth={ screenWidth }
         //          bundleId={ bundleId }
         //          onDeleteMessag={ onDeleteMessag }
         //          updateVaultMessageAction={ updateVaultMessageAction }
         //          updateMessageUnlockDetails={ updateMessageUnlockDetails }
         //          contentType={ contentType }
         //          isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
         //       />
         //    </div>
         default:
            return  <div className='vault-page h-full'>
               <ImagesContent
                  isInsideModal={ isInsideModal }
                  data={ vaultImagesData }
                  isFetchingByFilter={ isFetchingImagesByFilter }
                  isFetching={ isFetchingImages }
                  isEmpty={ isEmptyImages }
                  isNewFetching={ isNewFetchingImages }
                  desktopType={ desktopType }
                  openDeleteConfirmModal={ openDeleteConfirmModal }
                  removeFile={ removeFile }
                  selectedCards={ selectedCards }
                  selectCard={ selectCard }
                  filters={ allFilters }
                  handleAddNewVault={ handleAddNewVault }
                  contentType={ contentType }
                  screenWidth={ screenWidth }
                  onDeleteMessag={ onDeleteMessag }
                  updateVaultMessageAction={ updateVaultMessageAction }
                  updateMessageUnlockDetails={ updateMessageUnlockDetails }
                  isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                  onFilterChange={ onFilterChange }
                  onClearAllFilters={ onClearAllFilters }
                  isMultipleAttachments={ isMultipleAttachments }
                  isEmptyByFilter={ isEmptyImagesByFilter }
                  bundleId={ bundleId }
               />
            </div>
      }
   }

   return (
      <div className='vault-page flex flex-col h-full'>
         <TabSwitcher
            initialActiveTab={ pageType }
            parentOnChangeCallback={ (tab, e) => {
               onTabChange(tab)
               const elm = document.querySelector('.tabs')
               const item = e.target.getBoundingClientRect()
               let left = (item.left - item.width)
               elm.scrollBy(left, 0)
            }  }
         >
            <div className='w-full bg-panel tabs pr-4'>
               <div className='flex mr-4 max-tabs-width'>
                  <Tab id='image' classNames='p-3 font-normal' name='Images' />
                  <Tab id='video' classNames='p-3 font-normal' name='Videos' />
               </div>
            </div>
            <TabPanel whenActive={ pageType }>
               {
                  pageType === 'video' ? (
                     <VideosContent
                        isInsideModal={ isInsideModal }
                        data={ vaultVideosData }
                        isFetching={ isFetchingVideos }
                        isEmpty={ isEmptyVideos }
                        isFetchingByFilter={ isFetchingVideosByFilter }
                        isNewFetching={ isNewFetchingVideos }
                        desktopType={ desktopType }
                        openDeleteConfirmModal={ openDeleteConfirmModal }
                        removeFile={ removeFile }
                        selectedCards={ selectedCards }
                        selectCard={ selectCard }
                        filters={ allFilters }
                        handleAddNewVault={ handleAddNewVault }
                        pageType={ pageType }
                        isDownloading={ isDownloading }
                        downloadOriginalFile={ downloadOriginalFile }
                        updateMessageUnlockDetails={ updateMessageUnlockDetails }
                        isDemoMode={ isDemoMode }
                        onFilterChange={ onFilterChange }
                        onClearAllFilters={ onClearAllFilters }
                        screenWidth={ screenWidth }
                        isEmptyByFilter={ isEmptyVideosByFilter }
                        goTo={ goTo }
                     />
                  ) : (
                     <ImagesContent
                        isInsideModal={ isInsideModal }
                        data={ vaultImagesData }
                        isFetchingByFilter={ isFetchingImagesByFilter }
                        isFetching={ isFetchingImages }
                        isEmpty={ isEmptyImages }
                        isNewFetching={ isNewFetchingImages }
                        desktopType={ desktopType }
                        openDeleteConfirmModal={ openDeleteConfirmModal }
                        removeFile={ removeFile }
                        selectedCards={ selectedCards }
                        selectCard={ selectCard }
                        filters={ allFilters }
                        handleAddNewVault={ handleAddNewVault }
                        updateMessageUnlockDetails={ updateMessageUnlockDetails }
                        isDemoMode={ isDemoMode }
                        onFilterChange={ onFilterChange }
                        onClearAllFilters={ onClearAllFilters }
                        screenWidth={ screenWidth }
                        isEmptyByFilter={ isEmptyImagesByFilter }
                        goTo={ goTo }
                        downloadOriginalFile={ downloadOriginalFile }
                     />
                  )
               }
            </TabPanel>
         </TabSwitcher>
      </div>
   )
}

Vault.propTypes = {
   isInsideModal: PropTypes.bool,
   onFilterChange: PropTypes.func,
   onTabChange: PropTypes.func,
   handleAddNewVault: PropTypes.func,
   removeFile: PropTypes.func,
   desktopType: PropTypes.string,
   pageType: PropTypes.string,
   openDeleteConfirmModal: PropTypes.func,
   onSelectCard: PropTypes.func,
   selected: PropTypes.array,
   // images
   vaultImagesData: PropTypes.array,
   isEmptyImages: PropTypes.bool,
   isFetchingImages: PropTypes.bool,
   isFetchingImagesByFilter: PropTypes.bool,
   isNewFetchingImages: PropTypes.bool,
   // videos
   vaultVideosData: PropTypes.array,
   isFetchingVideos: PropTypes.bool,
   isEmptyVideos: PropTypes.bool,
   isFetchingVideosByFilter: PropTypes.bool,
   isNewFetchingVideos: PropTypes.bool,
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   // all vault
   showNewStyles: PropTypes.bool,
   allVaultData: PropTypes.array,
   isFetchingAllVault: PropTypes.bool,
   isEmptyAllVault: PropTypes.bool,
   isEmptyAllVaultByFilter: PropTypes.bool,
   isFetchingAllVaultByFilter: PropTypes.bool,
   onFilterByType: PropTypes.func,
   isNewfetchingAllVaults: PropTypes.bool,
   screenWidth: PropTypes.number,
   bundleId: PropTypes.any,
   contentType: PropTypes.string,
   updateVaultMessageAction: PropTypes.func,
   onDeleteMessag: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   isMultipleSelect: PropTypes.bool,
   isMultipleAttachments: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   isMaxAttachmentCountReached: PropTypes.bool,
   onClearAllFilters: PropTypes.func,
   allFilters: PropTypes.object,
   isEmptyImagesByFilter: PropTypes.bool,
   isEmptyVideosByFilter: PropTypes.bool,
   goTo: PropTypes.func,
}

Vault.defaultProps = {
   showNewStyles: false,
   bundleId: null,
   contentType: '',
}


export default Vault;

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import VideoJsHlsPlayer from '../video-js-hls-player'
import Modal from 'common/components/modules/modal'
import Button from 'common/components/elements/buttons/primary'
import TruncateText from 'common/components/elements/truncate-text'
import Photoset from 'common/components/modules/chat/show-content/photoset/index.jsx'
import Video from 'common/components/modules/chat/show-content/video/index.jsx';


const ContentPreviewIntoModal = ({
   type,
   data: {
      hlsManifest,
      posterSrc,
      isVrPlaying = false,
      title,
      photos = [],
      isVaultVideo = true,
      isPreview,
      duration,
      src,
      dash_manifest,
      drm_protection,
   },
   isMobile,
   closeModal,
   galleryProps,
   getLicenseToken,
   getDrmLicense,
}) => {

   return (
      <Modal
         contentPosition={ !isMobile ? 'center' : 'bottom' }
         className='h-full overflow-hidden'
         onClose={ closeModal }
         otherClassName={ 'w-full' }
         closeOnClickOutside={ false }
         contentWidth={ !isMobile ? '80vw' : '' }
      >
         <div
            className='px-6 pr-2 py-4 flex justify-between gap-3'
            role='presentation'
            onClick={ (e) => {
               e.stopPropagation()
            } }
         >
            <TruncateText
               text={ title }
               className='panel-header text-major dark:text-major-dark cursor-default'
               fontWeight='700'
               textSize='20px'
            />
            <Button
               iconName='close'
               fontIconColor='secondary'
               darkIconColor='secondary-dark'
               backgroundColor='transparent'
               onClick={ closeModal }
               padding='none'
               classNames='!h-6 !w-6 mr-2.5'
               fontIconSize='extraLarge'
            />
         </div>
         <div className='p-6 pt-0'
            role='presentation'
            onClick={ (e) => {
               e.stopPropagation()
            } }>
            {
               type === 'video' ?
                  (
                     isVaultVideo ?
                        <Video
                           poster={ posterSrc }
                           duration={ duration }
                           src={ src }
                           isPreview={ isPreview }
                           addView={ () => {} }
                           dashUri={ dash_manifest }
                           isDrmProtected={ drm_protection }
                           getLicenseToken={ getLicenseToken }
                           getDrmLicense={ getDrmLicense }
                        />
                        :
                        <VideoJsHlsPlayer
                           src={ src }
                           poster={ posterSrc }
                           isAutoPlay={ false }
                           isVrPlaying={ isVrPlaying }
                           dashUri={ dash_manifest }
                           isDrmProtected={ drm_protection }
                           getLicenseToken={ getLicenseToken }
                           getDrmLicense={ getDrmLicense }
                        />
                  )
                  :
                  (
                     <Photoset
                        data={ photos }
                        galleryProps={ galleryProps }
                     />
                  )
            }
         </div>
      </Modal>
   )
}

ContentPreviewIntoModal.propTypes = {
   data: PropTypes.object,
   type: PropTypes.string,
   isMobile: PropTypes.bool,
   closeModal: PropTypes.func,
   galleryProps: PropTypes.object,
   getLicenseToken: PropTypes.func,
   getDrmLicense: PropTypes.func,
}

export default memo(ContentPreviewIntoModal)

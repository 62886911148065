import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import Loader from 'common/components/elements/loader/circle';
import MediaChip from 'common/components/modules/media-chip'
import QueryParams from 'admin/utils/QueryParams';
// import { Link } from 'react-router-dom';

const UploadingCard = ({
   posterImage,
   uploadingStatus,
   className,
   onEditAndPublish,
   onCancel,
   onPause,
   onResume,
   percentage,
   type,
   link,
   isShowCancel,
   children,
   countOrDuration,
   isBulkEdit,
   isBulkEditSelected,
   onSelectMedia,
   hasTitle,
   disabeldCancel,
   dataToolTipContent,
   isUnzipingProcess,
   data = {},
}) => {
   const cardState = {
      optimizing: {
         view: (
            <div
               style={ {
                  marginBottom: '10px',
               } }
            >
               <Loader color='white' />
            </div>
         ),
         text: 'Optimizing',
         description: percentage && percentage > 0 ? `${ percentage }% completed` : '',
      },
      uploading: {
         view: (
            <div
               style={ {
                  marginBottom: '10px',
               } }
            >
               <Loader color='white' />
            </div>
         ),
         text: 'Uploading',
         description: percentage ? `${ percentage }% completed` : '',
      },
      paused: {
         view: (
            <span
               className='flex'
               style={ {
                  marginBottom: '3px',
               } }
               onClick={ onResume }
               role='presentation'
            >
               <Icon name='retry' color='major-dark' size='3xl' className='cursor-pointer' />
            </span>
         ),
         text: 'Paused',
         description: `Tap to restart upload`,
      },
      pending: {
         view: (
            <span
               className='flex'
               style={ {
                  marginBottom: '6px',
               } }
            >
               <Icon name='clock' color='major-dark' size='3xl' />
            </span>
         ),
         text: 'Pending',
         description: 'Upload will start soon',
      },
      in_optimization_queue: {
         view: (
            <span
               className='flex'
               style={ {
                  marginBottom: '6px',
               } }
            >
               <Icon name='clock' color='major-dark' size='3xl' />
            </span>
         ),
         text: 'In optimization queue',
         description: 'Optimization will start soon',
      },
      error: {
         view: (
            <span
               className='flex'
               style={ {
                  marginBottom: '6px',
               } }
            >
               <Icon name='attention' color='error' size='2xl' />
            </span>
         ),
         text: 'Error',
         description: 'Try again or ',
      },
      unziping: {
         view: (
            <div className='absolute w-full h-full flex items-center justify-center'>
               <div className='flex flex-col gap-2 items-center justify-center'>
                  <Icon
                     name='zip'
                     color='major-dark'
                     size='[32px]'
                  />
                  <p className='text-major-dark text-sm leading-5'>Unzipping...</p>
               </div>
            </div>
         ),
         text: '',
         description: '',
      },
   }

   return (

      <div
         role='presentation'
         onClick={ isBulkEdit ? () => onSelectMedia(!isBulkEditSelected) : null }
         className={ cx({
            [`flex flex-col rounded-t-label uploading-card overflow-hidden relative ${ className }`]: true,
            'bg-center bg-contain bg-no-repeat relative bg-panel-dark upload-card-shadow': uploadingStatus === 'ready',
            'bg-placeholder': (uploadingStatus !== 'ready' && uploadingStatus !== 'error') || isUnzipingProcess,
            // 'bg-panel border border-divider': uploadingStatus === 'error',
            'cursor-pointer': isBulkEdit,
            'rounded-t-label': hasTitle,
            'rounded-label': !hasTitle,
         }) }
      >
         <div className='aspect-ratio-padding' />
         <div
            className={ cx({
               'flex flex-col  uploading-card overflow-hidden absolute inset-0 w-full h-full': true,
               'rounded-t-label': hasTitle,
               'rounded-label': !hasTitle,
            }) }
         >
            {
               uploadingStatus === 'ready' && (
                  <>
                     {
                        isUnzipingProcess ?
                           cardState.unziping.view
                           :
                           <>
                              <img src={ posterImage } alt='' className='absolute inset-0 w-full h-full object-center object-contain' />
                              <div
                                 className={ cx({
                                    'bg-linear-gradient absolute inset-0 w-full h-full': true,
                                    'rounded-t-label': hasTitle,
                                    'rounded-label': !hasTitle,
                                 }) }
                              />
                           </>
                     }
                  </>

               )
            }
            <div
               className={
                  cx({
                     'flex': true,
                     'flex-row-reverse justify-between p-2 pr-0': uploadingStatus === 'ready',
                     'justify-start p-2 pr-0': uploadingStatus !== 'ready',
                  })
               }
            >
               {
                  uploadingStatus === 'ready' ? (
                     <div className='z-10 uploading-card__time self-end rounded flex items-center p-1'>
                        <MediaChip
                           type={ type  }
                           countOrDuration={ countOrDuration }
                        />
                     </div>
                  ) : (
                     <></>
                  )
               }
               {
                  !isBulkEdit && isShowCancel && (('photoset' === type && uploadingStatus === 'ready') || 'photoset' !== type) && (
                     <Button
                        onClick={ (e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           onCancel(e)
                        } }
                        iconName='cancel'
                        fontIconColor={ uploadingStatus !== 'error' ? 'major-dark' : 'secondary' }
                        classNames={ `!h-5 !w-5 flex items-center justify-center ${ disabeldCancel ? 'opacity-50' : '' }` }
                        padding='none'
                        backgroundColor='transparent'
                        disabled={ disabeldCancel }
                        dataToolTipContent={ dataToolTipContent || 'Delete' }
                     />
                  )
               }
               {
                  isBulkEdit && (
                     <div className='w-6 h-6 flex items-center justify-center z-1'>
                        <Icon
                           name={ isBulkEditSelected ? 'box-checked' : 'box-unchecked' }
                           size='2xl'
                           color='major-dark'
                        />
                     </div>
                  )
               }

            </div>
            <div
               className={ cx({
                  'flex flex-1': true,
                  'items-center justify-center flex-col': uploadingStatus !== 'ready',
                  'items-end justify-between': uploadingStatus === 'ready',
                  'border border-divider border-b-0 rounded-t-lg overflow-hidden': uploadingStatus === 'error' && !['photoset', 'video'].includes(type),
               }) }>
               {
                  uploadingStatus !== 'ready' && cardState[uploadingStatus] && (
                     <>
                        { cardState[uploadingStatus].view }
                        <span
                           className={ cx({
                              'font-medium text-sm block mb-2': true,
                              'text-major-dark': uploadingStatus !== 'error',
                              'text-error': uploadingStatus === 'error',
                           }) }  >{cardState[uploadingStatus].text} </span>
                        <span
                           className={ cx({
                              'text-major-dark text-sm block m-t-px': true,
                              'text-major-dark': uploadingStatus !== 'error',
                              'text-secondary flex flex-row gap-1 text-nowrap': uploadingStatus === 'error',
                           }) }>
                           {cardState[uploadingStatus].description}
                           {
                              uploadingStatus === 'error' &&
                              <Button
                                 text='Contact support'
                                 backgroundColor='transparent'
                                 textColor='secondary'
                                 textMarginX='0'
                                 padding='0'
                                 classNames='!h-fit !inline !underline intercom-video-uploading-error'
                                 textSize='small'
                                 onClick={ () => {
                                    window.Intercom('trackEvent', 'video_upload_failed', data);
                                    // window.Intercom('showNewMessage', 'https://dashboard.mymember.site/uploads?video-uploading=error');
                                    QueryParams.addQueryParam('video-uploading-error', true)
                                 } }
                              />
                           }
                        </span>
                     </>
                  )
               }
               {
                  uploadingStatus === 'ready' && (
                     children ? (
                        children
                     ) : (
                        <>
                           <div className='flex justify-end w-full pr-1 pb-1'>
                              <Button
                                 text='Edit & publish'
                                 iconName='arrow-right'
                                 classNames='!w-fit px-3.5 py-2'
                                 padding='none'
                                 backgroundColor='panel'
                                 textColor='major'
                                 fontIconColor='secondary'
                                 fontIconSize='extraLarge'
                                 textSize='small'
                                 borderRadius='large'
                                 onClick={ onEditAndPublish }
                                 textMarginX='0'
                                 textClassName='mr-2'
                              />
                           </div>
                        </>
                     )
                  )
               }

            </div>
         </div>

      </div>
   );
};

UploadingCard.propTypes = {
   countOrDuration: PropTypes.number,
   posterImage: PropTypes.string,
   uploadingStatus: PropTypes.string,
   className: PropTypes.string,
   onEditAndPublish: PropTypes.func,
   onCancel: PropTypes.func,
   onPause: PropTypes.func,
   onResume: PropTypes.func,
   type: PropTypes.string,
   percentage: PropTypes.any,
   link: PropTypes.string,
   isShowCancel: PropTypes.bool,
   children: PropTypes.any,
   isBulkEdit: PropTypes.bool,
   onSelectMedia: PropTypes.func,
   isBulkEditSelected: PropTypes.bool,
   hasTitle: PropTypes.bool,
   disabeldCancel: PropTypes.func,
   dataToolTipContent: PropTypes.string,
   isUnzipingProcess: PropTypes.bool,
   data: PropTypes.object,
};
UploadingCard.defaultProps = {
   className: 'uploading-card',
   onEditAndPublish: () => {},
   onCancel: () => {},
   onPause: () => {},
   onResume: () => {},
   percentage: 0,
   isShowCancel: true,
   isUnzipingProcess: false,
   dataToolTipContent: '',
};

export default React.memo(UploadingCard);

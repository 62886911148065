import React, { useState, useEffect, useRef } from 'react';
import AutoNotification from './auto-notification';
import Masonry from 'react-masonry-css'
import cx from 'classnames';
import Button from 'common/components/elements/buttons/primary';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { history } from 'admin/state/store';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import { getObjectUrl, checkVaultByHash, uploadPhotosetsImage } from 'admin/api/AuthApi';
import { getImageFileHash, getVideoFileHash } from 'common/utils/utils';
import { getIsCannotBeTurnedOff } from 'admin/views/my-site/settings/outgoing-notification-and-auto-tweet/data';

import { makeRandomString } from 'common/utils/utils';
import {
   uploadingVideo,
   cancelUplaod,
} from 'admin/utils/VaultVideoUpload';
import Editor from 'common/components/modules/editor';
import toast from 'common/utils/toast';
import { AutoNotificationWrrapper } from './modules';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_AUTO_POSTS, ARTICLE_ID_ON_MASS_MESSAGES } from 'common/utils/intercom-articles';
// import Icon from 'common/components/elements/icons';


const OutgoingNotificationsAndAutoTweetEdit = ({
   screenWidth,
   data,
   onSaveData,
   isFetching,
   goBack,
   twitterAccount,
   onClickConnectTwitterAccount,
   isNonContent,
   eventName,
   individualVariables,
   siteInfo,
   errors,
   clearErrorState,
   fetchingConnectedTwitterAccount,
   isInsideModal,
   insideModalCallBack,
   isDemoMode,
}) => {
   const  hasResourcesKey = ['resources_type', 'resources', 'cover'];  // video_type , resources_id, duration;
   let editorRef = useRef(null);
   let defaultEditorRef = useRef(null);
   let removeImgsArrays = useRef(null);
   const [settings, setSettings] = useState(data);
   const [updateData, setUpdateData] = useState({});
   const isMobile = screenWidth < 1025;
   const [nextRoute, setNextRoute] = useState(null);
   const [openDiscardModal, setOpenDiscardModal] = useState(false);
   const uploadVideoItem = useRef(null);
   const unblock = useRef(null);

   const setEditorValue = (currentEditor, body, bool = true) => {
      if(currentEditor?.editor?.clipboard?.quill?.clipboard){
         if(bool){
            const delta = currentEditor.editor.clipboard.convert(body.trim());
            currentEditor.editor.setContents(delta, 'user');
         }
         const processedHtml = currentEditor.editor.root.innerHTML.replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "").trim(" ");

         return processedHtml;
      }
   }

   const checkPayloadAll = (bool) => {
      const payloadData = {};
      let oldPayloadData = {};
      if(data?.auto_tweet_payload){
         payloadData.auto_tweet_is_on = settings.auto_tweet_is_on;
         oldPayloadData.auto_tweet_is_on = data.auto_tweet_is_on;
         payloadData.auto_tweet_payload = {
            body: settings.auto_tweet_payload.body,
         };
         oldPayloadData.auto_tweet_payload = {
            body: data.auto_tweet_payload.body,
         }
         if(isNonContent){
            oldPayloadData.auto_tweet_payload.cover = data.auto_tweet_payload.cover;
            payloadData.auto_tweet_payload.cover = settings.auto_tweet_payload.cover
         }
      }
      if(data?.individual_private_message_payload){
         payloadData.individual_private_message_is_on = settings.individual_private_message_is_on;
         oldPayloadData.individual_private_message_is_on = data.individual_private_message_is_on;
         payloadData.individual_private_message_payload = {
            body: settings.individual_private_message_payload.body,
            subject: settings.individual_private_message_payload.subject,
            edited: settings.individual_private_message_payload.edited,
         };
         oldPayloadData.individual_private_message_payload = {
            body: data.individual_private_message_payload.body,
            subject: data.individual_private_message_payload.subject,
            edited: data.individual_private_message_payload.edited,
         }
         if(isNonContent){
            let updateDataKey = [...hasResourcesKey];
            for(const iterator of updateDataKey) {
               oldPayloadData.individual_private_message_payload[iterator] = data.individual_private_message_payload[iterator];
               payloadData.individual_private_message_payload[iterator] = settings.individual_private_message_payload[iterator]
            }
         }
      }

      if(data?.mass_private_message_payload){
         payloadData.mass_private_message_is_on = settings.mass_private_message_is_on;
         oldPayloadData.mass_private_message_is_on = data.mass_private_message_is_on;
         payloadData.mass_private_message_payload = {
            body: settings.mass_private_message_payload.body,
            subject: settings.mass_private_message_payload.subject,
            edited: settings.mass_private_message_payload.edited,
         };
         oldPayloadData.mass_private_message_payload = {
            body: data.mass_private_message_payload.body,
            subject: data.mass_private_message_payload.subject,
            edited: data.mass_private_message_payload.edited,
         }
         if(isNonContent){
            let updateDataKey = [...hasResourcesKey];
            for(const iterator of updateDataKey) {
               oldPayloadData.mass_private_message_payload[iterator] = data.mass_private_message_payload[iterator];
               payloadData.mass_private_message_payload[iterator] = settings.mass_private_message_payload[iterator]
            }
         }
      }
      if(editorRef?.current?.editor){
         if(oldPayloadData?.mass_private_message_payload?.body){
            oldPayloadData.mass_private_message_payload.body = setEditorValue(defaultEditorRef.current, oldPayloadData.mass_private_message_payload.body);
         }
         if(payloadData?.mass_private_message_payload?.body){
            payloadData.mass_private_message_payload.body = setEditorValue(editorRef.current, payloadData.mass_private_message_payload.body, false);
         }
         if(oldPayloadData?.individual_private_message_payload?.body){
            oldPayloadData.individual_private_message_payload.body = setEditorValue(defaultEditorRef.current,  oldPayloadData.individual_private_message_payload.body);
         }
         if(payloadData?.individual_private_message_payload?.body){
            payloadData.individual_private_message_payload.body = setEditorValue(editorRef.current, payloadData.individual_private_message_payload.body, false);
         }
      }
      if(bool){
         console.log(payloadData, oldPayloadData, 'TESTING')
      }
      return isEqual(payloadData, oldPayloadData)
   }
   const isEqualData = checkPayloadAll();
   // useEffect(() => {
   //    window.addEventListener('content-update', bindSocketEvents);
   //    return () => {
   //       window.removeEventListener('content-update', bindSocketEvents);
   //    }
   // // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, []);


   useEffect(() => {
      unblock.current = history.block(({ pathname }, action) => {
         if('REPLACE' !== action){
            if(checkPayloadAll(true)) {
               unblock.current();
            } else {
               setOpenDiscardModal(true);
               setNextRoute(pathname);
               return false;
            }
         }
      })
      return () => {
         if(unblock.current){
            unblock.current();
         }
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, settings]);

   // const bindSocketEvents = e => {

   //    if(e){
   //       const { detail: { payload } } = e
   //       const video = payload;
   //       const {  socket_emit_type } = video;
   //       if('vault_upload' === socket_emit_type && video.id && uploadVideoItem.current?.id === video.id) {
   //          let newShowData = {
   //             id: video.id,
   //             type: 'video',
   //             resources: [video.src],
   //             full_src: video.video_poster_full_src,
   //             src: video.src,
   //             duration: video.video_duration,
   //             percentage: video.video_optimization_percentage,
   //             status: video.video_optimization_status,
   //          }
   //          const name = uploadVideoItem.current?.update_settings_name || 'individual_private_message_payload';
   //          onChangeNotificationPayload(name, {
   //             resources_data: [newShowData],
   //             resources_type: 'video',
   //             duration: video.video_duration,
   //             resources: [video.src],
   //             video_type: 'vault',
   //             resources_id: video.id,
   //             cover: video.video_poster,
   //          });
   //          if('completed' === video.video_optimization_status){
   //             const  sendData = {
   //                cover: video.video_poster,
   //                resources_type: 'video',
   //                duration: video.video_duration,
   //                resources: [video.src],
   //                video_type: 'vault',
   //                resources_id: video.id,
   //             }
   //             setUpdateData(old => {
   //                return {
   //                   ...old,
   //                   [name]: {
   //                      body: old?.[name]?.body || settings?.[name]?.body || '',
   //                      subject: old?.[name]?.subject || settings?.[name]?.subject || '',
   //                      ...sendData,
   //                   },
   //                }
   //             });
   //          }
   //       }
   //    }
   // }

   const getContentWidth = () => {
      let width = '100%';
      if(screenWidth > 1024 && screenWidth < 1301) width = 'calc(100% - 50px)';
      if(screenWidth > 1300) width = 'calc(100% - 260px)';
      if(isInsideModal) width = '100%'
      return width;
   }

   const settingsIsEnabled = (key) => {
      return null !== settings?.[key]
   }

   const checkPayload = (payload, oldPayload, key) => {
      let payloadData = {};
      const oldPayloadData = { ...oldPayload };


      if(oldPayloadData){
         for(const key in oldPayloadData) {
            if([key] in payload){
               const element = payload[key];
               payloadData[key] = element;
            }
         }
      } else {
         payloadData = null;
      }

      if(editorRef?.current?.editor && 'auto_tweet' !== key){
         if(oldPayloadData?.body){
            oldPayloadData.body = setEditorValue(defaultEditorRef.current, oldPayloadData.body, false);
         }
         if(payloadData?.body){
            payloadData.body = setEditorValue(editorRef.current, payloadData.body, false);
         }
      }
      return isEqual(payloadData, oldPayloadData)
   }

   const getState = (key) => {
      const payload = settings?.[`${ key }_payload`];
      const oldPayload = data?.default_config?.[`${ key }_payload`] ||  data?.[`${ key }_payload`];
      const initialPayload = settings?.default_config?.[`${ key }_payload`];
      let checked = settings?.[`${ key }_is_on`] || false;
      if('auto_tweet' === key && !Boolean(twitterAccount)){
         checked = false;
      }

      const isEqualData = checkPayload(payload, oldPayload, key);
      return {
         payload,
         checked,
         screenWidth,
         initialPayload,
         onChangeData: onChangeData,
         onClickRevert: onClickRevert,
         attachFile: isNonContent,
         uploadFile: uploadFile,
         uploadImages: uploadImages,
         uploadVideo: uploadVideo,
         onClickRemoveMedia: removeFile,
         individualVariables: individualVariables,
         isEqualData: isEqualData,
         isMobile: isMobile,
         siteInfo: siteInfo,
         oldData: data.default_config,
         checkPayload: checkPayload,
         slug: data.slug,
         errors: errors,
         clearErrorState: clearErrorState,
      }
   }

   const onChangeData = (name, value, callback) => {
      setSettings({
         ...settings,
         [name]: value,
      });
      setUpdateData({
         ...updateData,
         [name]: value,
      });
      if(callback){
         callback()
      }
   }
   const onClickRevert = async (slug, callback) => {
      const payload = settings[slug];
      let videoUploadData = null;
      let status = null;
      const resourcesType = payload?.resources_type;
      if('video' === resourcesType && payload?.resources_data?.[0]){
         videoUploadData = payload?.resources_data[0].videoUpload;
         status = payload?.resources_data[0].status;
      } else if('photo' === resourcesType) {
         const data = payload?.resources_data;
         for(const el of data) {
            if(el.status === 'loading'){
               if(!removeImgsArrays.current){
                  removeImgsArrays.current = {
                     [el.file.name]: el.file,
                  }
               } else {
                  removeImgsArrays.current = {
                     ...removeImgsArrays.current,
                     [el.file.name]: el.file,
                  }
               }

            }
         }
      }
      if(videoUploadData && status && ('uploading' ===  status || 'pending' ===  status)) {
         cancelUplaod(videoUploadData, () => onClickRemoveMedia(videoUploadData, slug))

      }
      const defaultConfig = data.default_config;
      if(defaultConfig?.[slug]){
         onChangeData(slug, { ...defaultConfig[slug], edited: false }, callback);
         toast.success('Your notification reverted to the default');
      }
   }
   const onChangeNotificationPayload = (name, data) => {
      setSettings(old => {
         return {
            ...old,
            [name]: {
               ...old[name],
               ...data,
            },
         }
      });
   }

   const removeFile = async (item, name) => {
      const payload = settings[name];
      let videoUploadData = null;
      let status = null;
      const resourcesType = payload?.resources_type;
      if('video' === resourcesType && payload?.resources_data?.[0]){
         videoUploadData = payload?.resources_data[0].videoUpload;
         status = payload?.resources_data[0].status;
      }
      if(videoUploadData && status && ('uploading' ===  status || 'pending' ===  status)) {
         cancelUplaod(videoUploadData, () => onClickRemoveMedia(item, name))
      } else {
         if(item.file && 'loading' === item.status){
            if(!removeImgsArrays.current){
               removeImgsArrays.current = {
                  [item.file.name]: item.file,
               }
            } else {
               removeImgsArrays.current = {
                  ...removeImgsArrays.current,
                  [item.file.name]: item.file,
               }
            }
         }
         onClickRemoveMedia(item, name)
      }
      toast.success('Attachment has been removed');
   }


   const onClickRemoveMedia = (item, name) => {
      const payload = settings[name];
      let resources = Array.isArray(payload?.resources_data) ? [...payload.resources_data] : [];
      let newResources = Array.isArray(payload?.resources) ? [...payload.resources] : [];
      resources = resources.filter(el => el.src !== item.src)
      newResources = newResources.filter(el => el !== item.src)
      let newState = {}
      if(newResources.length === 0){
         const oldPayloadData = {
            body: data.default_config?.[name].body,
            subject: data.default_config?.[name].subject,
         }
         const payloadData = {
            body: payload.body,
            subject: payload.subject,
         }
         let edited = payload?.edited;
         if(editorRef?.current?.editor){
            if(oldPayloadData?.body){
               oldPayloadData.body = setEditorValue(defaultEditorRef.current, oldPayloadData.body, false);
            }
            if(payloadData?.body){
               payloadData.body = setEditorValue(editorRef.current, payloadData.body, false);
            }
         }
         if(isEqual(oldPayloadData, payloadData)){
            edited = false;
         }

         newState = {
            cover: null,
            resources_type: null,
            resources_data: null,
            duration: null,
            resources: null,
            edited,
         }
         onChangeNotificationPayload(name, {
            ...newState,
         });

         setUpdateData(old => {
            return {
               ...old,
               [name]: {
                  body: settings[name].body,
                  subject: settings[name].subject,
                  ...newState,
               },
            }
         });

      } else {
         onChangeNotificationPayload(name, {
            resources_data: resources,
            resources: newResources,
         });
         setUpdateData(old => {
            return {
               ...old,
               [name]: {
                  body: settings[name].body,
                  subject: settings[name].subject,
                  resources: newResources,
               },
            }
         });
      }
   }
   const uploadFile = async (e, type, isTweet) => {

      if(isTweet){
         const file = e;
         if(!file) return
         const type = file.name.split('.');
         const validFormat = ['png', 'jpeg', 'jpg'];
         const isValid = validFormat.indexOf(type[type.length - 1].toLowerCase()) !== -1 && type.length > 1;
         onChangeNotificationPayload('auto_tweet_payload', {
            isLoadingUpload: true,
            uploadError: null,
            cover_full_src: null,
         });
         if(isValid) {
            const formData = new FormData();
            formData.append('file', file);
            try {
               const { data: { path } } =  await uploadPhotosetsImage(formData);
               const { data } =  await getObjectUrl([path], 'images/thumb-');
               onChangeNotificationPayload('auto_tweet_payload', {
                  cover: path,
                  cover_full_src: data[path],
                  isLoadingUpload: false,
                  uploadError: null,
               });
               setUpdateData(old => {
                  return {
                     ...old,
                     'auto_tweet_payload': {
                        ...settings.auto_tweet_payload,
                        cover: path,
                     },
                  }
               });
            } catch (error) {
               if(error.message){
                  onChangeNotificationPayload('auto_tweet_payload', {
                     isLoadingUpload: false,
                     uploadError: error.message,
                  });
               }
            }
         } else {
            onChangeNotificationPayload('auto_tweet_payload', {
               isLoadingUpload: false,
               uploadError: 'This image format is not supported',
            });
         }
      }
   }
   const uploadImages = async (e, name) => {
      const uploadFiles = [...e.target.files];
      const payload = settings[name];
      e.target.value = null;
      let resources = Array.isArray(payload?.resources_data) ? [...payload.resources_data] : [];
      let newResources = Array.isArray(payload?.resources) ? [...payload.resources] : [];
      let hashs = []
      for(let i = 0; i < uploadFiles.length; i++) {
         await getImageFileHash(uploadFiles[i]).then(
            // eslint-disable-next-line no-loop-func
            (result) => {
               resources.push({ status: 'loading', hash: result, id: result, full_src: null, file: uploadFiles[i] })
               hashs.push(result)
            },
            function(err) {
            });
      }

      onChangeNotificationPayload(name, {
         resources_data: resources,
         resources_type: 'photo',
      });
      const { data } = await checkVaultByHash('image', [...hashs]);
      // const hashsToString = hashs ? hashs.join('') : '';

      let sortItems = data.reduce((acc, curr) => {
         let value =  Object.values(curr)[0]
         let hash =  Object.keys(curr)[0];

         if(value) {
            resources = [...resources].map(elm => {
               let { ...item } = elm
               if(item.hash === hash) {
                  item = { full_src: value.image_thumb_src, status: 'completed', id: value.id, src: value.src  }
               }
               return item
            })
            newResources = [...newResources, value.src]

            onChangeNotificationPayload(name, {
               resources_data: resources,
               resources: newResources,
               cover: newResources[0],
               resources_type: 'photo',
            });
            const newUpdateData = {
               body: settings[name].body,
               subject: settings[name].subject,
               resources: newResources,
               cover: newResources[0],
               resources_type: 'photo',
            }
            setUpdateData(old => {
               return {
                  ...old,
                  [name]: newUpdateData,
               }
            });
         } else {
            let current = resources.find(el => el.hash === hash);
            acc.push({ hash, file: current.file, ...current })
         }
         return acc
      }, []);
      for(let i = 0; i < sortItems.length; i++){
         const { file, hash } = sortItems[i];
         try {
            const data = new FormData();
            data.append('file', file);
            const { data: { path } } =  await uploadPhotosetsImage(data);
            if(removeImgsArrays.current && file.name in removeImgsArrays.current){
               delete removeImgsArrays.current[file.name]
               return
            }

            const fullSrc =  await getObjectUrl([path], 'images/thumb-');
            const imageSrc = fullSrc.data[path];

            resources = [...resources].map(elm => {
               let { ...item } = elm
               if(item.hash === hash) {
                  item = { full_src: imageSrc, status: 'completed', hash, id: makeRandomString(16), src: path };

               }
               return item
            })
            newResources = [...newResources, path]
            newResources = [...new Set(newResources)]
            onChangeNotificationPayload(name, {
               resources_data: resources,
               resources: newResources,
               cover: newResources[0],
               resources_type: 'photo',
            });
            const newUpdateData = {
               body: settings[name].body,
               subject: settings[name].subject,
               resources: newResources,
               cover: newResources[0],
               resources_type: 'photo',
            }
            setUpdateData(old => {
               return {
                  ...old,
                  [name]: newUpdateData,
               }
            });
         } catch (error) {
            console.log(error)
         }
      }
   }


   const uploadVideo = async (e, name) => {
      const files = e.target.files
      let file = files[0];
      let hash = getVideoFileHash(file);
      const { data } = await checkVaultByHash('video', [hash]);
      const value = data[0] ? data[0][hash] : {}
      if(value){
         let newShowData = {
            id: value.id,
            src: value.src,
            type: value.type,
            full_src: value.video_poster_full_src,
            percentage: value.video_optimization_percentage,
            status: value.video_optimization_status,
            count: value.video_duration,
            video_poster: value.video_poster,
            videoUpload: {
               ...value,
            },
         }
         uploadVideoItem.current = newShowData;
         uploadVideoItem.current.update_settings_name = name;
         onChangeNotificationPayload(name, {
            resources_data: [newShowData],
            resources_type: 'video',
            duration: value.video_duration,
            resources: [value.src],
            video_type: 'vault',
            resources_id: value.id,
            cover: value.video_poster,

         });
         const sendData = {
            cover: value.video_poster,
            resources_type: 'video',
            duration: value.video_duration,
            resources: [value.src],
            video_type: 'vault',
            resources_id: value.id,
         }
         setUpdateData(old => {
            return {
               ...old,
               [name]: {
                  body: settings[name].body,
                  subject: settings[name].subject,
                  ...sendData,
               },
            }
         });

      } else {
         startUploadingVideo(name, file, hash)
      }
   }

   const startUploadingVideo = (name, file, hash) => {
      let files = []
      if(file) {
         files = [{ file, hash }]
      }
      uploadingVideo(files, (id, data) => {
         let newShowData = {}
         if(!id) {
            const { vault } = data;
            newShowData = {
               type: 'video',
               id: vault[0].id,
               src: '',
               full_src: '',
               status: vault[0].uploadingStatus,
               percentage: data.video_optimization_percentage,
               duration: data.video_duration || 1200,
               videoUpload: {
                  ...vault[0],
               },
            }
            uploadVideoItem.current = { ...vault[0] }
         } else {
            newShowData = {
               id: data.id,
               type: 'video',
               resources: [],
               src: data.src,
               percentage: data.video_optimization_percentage,
               status: data.video_optimization_status || 'uploading',
               duration: data.video_duration,
               videoUpload: { ...uploadVideoItem.current, ...data, uploadingStatus: data.video_optimization_status || 'uploading' },
            }
            uploadVideoItem.current = data
         }
         if('video_optimization_status' === uploadVideoItem.current?.video_optimization_status){
            uploadVideoItem.current.update_settings_name = name;
         }
         onChangeNotificationPayload(name, {
            resources_data: [newShowData],
            resources_type: 'video',
         });
      }, () => uploadVideoItem.current,  () => startUploadingVideo(name))
   }


   const checkIsContentUploading = (payload) => {
      let result = false
      const data = payload?.resources_data;
      if(data){
         result = Boolean(data.find(el => el.status !== 'completed'));
      }
      return result
   }

   const getIsContentUploading = () => {
      if(!isNonContent){
         return false;
      }
      const autoTweetCoverUploaded = settings?.auto_tweet_payload && (settings.auto_tweet_payload?.isLoadingUpload || settings.auto_tweet_payload?.uploadError);
      const privateMessageUploadedContent = settings?.individual_private_message_payload && checkIsContentUploading(settings?.individual_private_message_payload);
      const massMessageUploadedContent = settings?.mass_private_message_payload && checkIsContentUploading(settings?.mass_private_message_payload);
      // return autoTweetCoverUploaded || privateMessageUploadedContent || massMessageUploadedContent;
      return (autoTweetCoverUploaded && 'Required' !== autoTweetCoverUploaded) || privateMessageUploadedContent || massMessageUploadedContent;
   }


   const defaultEditorValue = () => {
      if(settingsIsEnabled('mass_private_message_payload')){
         return data.mass_private_message_payload.body
      }
      if(settingsIsEnabled('individual_private_message_payload')){
         return data.individual_private_message_payload.body
      }
      return '';
   }
   useEffect(() => {
      if(isInsideModal) {
         insideModalCallBack({
            auto_tweet_is_on: !!settings?.auto_tweet_is_on,
            mass_private_message_is_on: !!settings?.mass_private_message_is_on,
            auto_tweet_payload: settings?.auto_tweet_payload,
            mass_private_message_payload: settings?.mass_private_message_payload,
            ...updateData,
         })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [updateData, isInsideModal, settings, data])

   return (
      <>
         {
            openDiscardModal && (
               <CreateEditModal
                  type='confirm'
                  icon='cancel'
                  iconColor='error'
                  descriptionColorClass='text-secondary'
                  action={ () => {
                     if(unblock.current){
                        unblock.current()
                     }
                     if(nextRoute){
                        history.push(nextRoute);
                     } else {
                        goBack();
                     }
                  } }
                  onCloseModal={ () => setOpenDiscardModal(false) }
                  title='Discard changes?'
                  description='Changes you have made so far will not be saved'
                  cancelText='Keep editing'
                  buttonText='Discard changes'
                  nonPrimaryColor
                  textColor='error'
                  contentWidth='375px'
                  isMobile={ isMobile }
                  buttonClassName='mt-8 text-error'
                  iconSize='2xl'
               />
            )
         }
         <div
            className={ cx({
               'w-full items-center': true,
               'py-8': !isMobile,
               'px-4 py-6': isMobile,
               '!p-0': isInsideModal,
            }) }
         >
            <div className='hidden'>
               <Editor
                  refProps={ defaultEditorRef }
                  label='Body'
                  name='body'
                  height={ 0 }
                  toolbar={ [] }
                  initialValue={ defaultEditorValue() }
                  // readOnly
               />
            </div>
            <Masonry
               breakpointCols={ {
                  default: 2,
                  1024: 1,
               } }
               style={ {
                  width: getContentWidth(),
               } }
               className='flex gap-6 mx-auto justify-center'
               columnClassName={ cx({
                  'w-full flex flex-col gap-6': true,
                  'max-w-[550px]': !isMobile,
                  '!w-[550px]': screenWidth > 1024 && isInsideModal,
               }) }
            >
               {
                  settingsIsEnabled('auto_tweet_payload') && !['new_poll_published', 'poll_24_hours_left', 'poll_ended'].includes(data?.slug) && (
                     <div className='flex flex-col'>
                        <EditState
                           title={ isInsideModal ? 'Post on X' : 'Auto post on X' }
                           name='auto_tweet'
                           icon='twitter-x'
                           isTweet
                           { ...getState('auto_tweet') }
                           needToConnectTwiter={ !Boolean(twitterAccount) }
                           onClickConnectTwitterAccount={ onClickConnectTwitterAccount }
                           fetchingConnectedTwitterAccount={ fetchingConnectedTwitterAccount }
                           isInsideModal={ isInsideModal }
                           isDemoMode={ isDemoMode }
                        />
                        <MoreInfoButton
                           text='More info on Auto posts'
                           onClick={ () => Intercom.showArticle(ARTICLE_ID_ON_AUTO_POSTS) }
                        />
                     </div>
                  )
               }
               {
                  settingsIsEnabled('individual_private_message_payload') && (
                     <EditState
                        title='Private message'
                        icon='messaging'
                        name='individual_private_message'
                        description={ `Modify the private message for the event “${ eventName }”` }
                        isInsideModal={ isInsideModal }
                        { ...getState('individual_private_message') }
                        editorRef={ editorRef }
                        isDemoMode={ isDemoMode }
                     />

                  )
               }
               {
                  settingsIsEnabled('mass_private_message_payload') && (
                     <div className='flex flex-col'>
                        <EditState
                           title='Mass message'
                           name='mass_private_message'
                           icon='messaging'
                           description={ `Modify the default mass message for the event “${ eventName }”.` }
                           isInsideModal={ isInsideModal }
                           { ...getState('mass_private_message') }
                           editorRef={ editorRef }
                           isDemoMode={ isDemoMode }
                        />
                        <MoreInfoButton
                           text='More info on mass messages'
                           onClick={ () => Intercom.showArticle(ARTICLE_ID_ON_MASS_MESSAGES) }
                        />
                     </div>

                  )
               }
            </Masonry>
            {
               !isInsideModal &&
               <>
                  <div
                     className={ cx({
                        'w-full': true,
                        'h-[76px]': !isMobile,
                        'h-[124px] ': isMobile,
                     }) } />
                  <div
                     style={ isMobile && screenWidth > 700 ? {
                        width: 'calc(100% - 56px)',
                     } : {} }
                     className={ cx({
                        'fixed bottom-0 flex items-center justify-center w-full bg-panel z-[62]': true,
                        'h-[76px]': !isMobile,
                        'h-[124px] flex-col-reverse px-4 right-0': isMobile,
                     }) }
                  >
                     <div
                        className={ cx({
                           'h-9 flex': true,
                           'absolute ml-8 left-0': !isMobile,
                        }) }
                     >
                        <Button
                           text='Cancel'
                           classNames='h-full px-3'
                           textMarginX='0'
                           borderRadius='large'
                           backgroundColor='transparent'
                           textColor='secondary'
                           padding='none'
                           textSize='base'
                           onClick={ () => {
                              if(isEqualData){
                                 goBack();
                                 return;
                              }
                              setOpenDiscardModal(true);
                           } }
                        />
                     </div>
                     <div
                        className={ cx({
                           'h-10': true,
                           'mb-3 w-full': isMobile,
                           'w-[143px]': !isMobile,
                        }) }

                     >
                        <Button
                           text='Save changes'
                           onClick={ _ => {
                              if(data?.auto_tweet_payload){
                                 let autoTweetCover =  data?.auto_tweet_payload?.cover;
                                 let autoTweetIsOn =  data?.auto_tweet_is_on;
                                 if('auto_tweet_payload' in updateData){
                                    autoTweetCover = updateData?.auto_tweet_payload?.cover;
                                 }
                                 if('auto_tweet_is_on' in updateData){
                                    autoTweetIsOn = updateData?.auto_tweet_is_on;
                                 }
                                 if(isNonContent && autoTweetIsOn && !autoTweetCover){

                                    onChangeNotificationPayload('auto_tweet_payload', {
                                       uploadError: 'Required',
                                       status: 'error',
                                    });
                                    return
                                 }
                              }
                              onSaveData(updateData, () => {
                                 if(unblock.current){
                                    unblock.current();
                                 }
                              })
                           } }
                           primaryColor
                           classNames='w-full h-full'
                           borderRadius='large'
                           disabled={ isFetching || isEqualData || getIsContentUploading() }
                           fontWeight='medium'
                           textSize='base'
                           padding='none'
                           textMarginX='0'
                        />
                     </div>
                  </div>
               </>
            }
         </div>
      </>
   );
};

const MoreInfoButton = ({ text, onClick }) => {
   return (
      <div className='flex justify-between mt-6 items-start'>
         <Button
            text={ text }
            textSize='small'
            textColor='action'
            // classNames={ `h-full w-full intercom-${ text.toLowerCase().split(' ').join('-') }` }
            classNames='h-full w-full'
            iconName='attention-outline'
            fontIconColor='action'
            iconPosition='left'
            alignment='end'
            backgroundColor='transparent'
            fontIconSize='base'
            textPosition='left'
            onClick={ Boolean(onClick) ? () => onClick() : () => {} }
         />
      </div>
   )
}


const EditState = ({
   title,
   icon,
   isTweet,
   name,
   description,
   attachFile,
   screenWidth,
   payload,
   checked,
   onChangeData,
   onClickRevert,
   initialPayload,
   needToConnectTwiter,
   onClickConnectTwitterAccount,
   uploadFile,
   uploadImages,
   onClickRemoveMedia,
   uploadVideo,
   individualVariables,
   isEqualData,
   isMobile,
   siteInfo,
   oldData,
   checkPayload,
   slug,
   errors,
   clearErrorState,
   fetchingConnectedTwitterAccount,
   editorRef,
   isInsideModal,
   isDemoMode,
}) => {

   const disableEditSwitch = getIsCannotBeTurnedOff(name, slug);

   const switchComponentTooltipText = () => {

      if(disableEditSwitch){
         return 'This message cannot be turned off';
      }
      return null
   }
   return (
      <AutoNotificationWrrapper
         title={ title }
         icon={ icon }
         name={ name }
         description={ !isInsideModal ? description : '' }
         checked={ checked }
         needToConnectTwiter={ needToConnectTwiter }
         onClickConnectTwitterAccount={ onClickConnectTwitterAccount }
         fetchingConnectedTwitterAccount={ fetchingConnectedTwitterAccount }
         tooltipText={ switchComponentTooltipText() }
         onChange={ _ => {
            if(disableEditSwitch) {
               return
            }
            onChangeData(`${ name }_is_on`, !checked)
         } }
         hasPointerCursor={ !disableEditSwitch }
         screenWidth={ screenWidth }
         isInsideModal={ isInsideModal }
      >
         {
            !needToConnectTwiter && checked && (
               <div className='flex flex-col items-start w-full'>
                  <AutoNotification
                     isTweet={ isTweet }
                     attachFile={ attachFile }
                     screenWidth={ screenWidth }
                     data={ payload }
                     initialData={ initialPayload }
                     onChangeData={ (inputName, value) => {
                        let edited = payload?.edited;
                        if(value === oldData?.[`${ name }_payload`]?.[inputName] && checkPayload(oldData?.[`${ name }_payload`], payload, name)){
                           edited = false;
                        }
                        onChangeData(`${ name }_payload`, { ...payload, [inputName]: value, edited })
                        if(clearErrorState &&  errors?.[`${ name }_payload.${ inputName }`]){
                           clearErrorState({
                              [`${ name }_payload.${ inputName }`]: null,
                           })
                        }
                     } }
                     onClickRevert={ (callback) => onClickRevert(`${ name }_payload`, callback) }
                     uploadFile={ uploadFile }
                     uploadImages={ e => uploadImages(e, `${ name }_payload`) }
                     uploadVideo={ e => uploadVideo(e, `${ name }_payload`) }
                     onClickRemoveMedia={ item => onClickRemoveMedia(item, `${ name }_payload`) }
                     onClickDeleteTweetAttachment={ item => {
                        let edited = payload?.edited;
                        if(oldData?.auto_tweet_payload?.body === payload?.body){
                           edited = false;
                        }
                        onChangeData('auto_tweet_payload', { ...payload, cover: null, cover_full_src: null, edited });
                        toast.success('Thumbnail has been deleted')
                     } }
                     individualVariables={ individualVariables }
                     isEqualData={ isEqualData }
                     isMobile={ isMobile }
                     slug={ slug }
                     siteInfo={ siteInfo }
                     errors={ {
                        subject: errors?.[`${ name }_payload.subject`] || null,
                        body: errors?.[`${ name }_payload.body`] || null,
                     } }
                     bodyRef={ editorRef }
                     isInsideModal={ isInsideModal }
                     attachmentDisabled={ isDemoMode }
                     disabledAttachmentTooltipText='Not available in demo'
                  />

               </div>
            )
         }
      </AutoNotificationWrrapper>
   )
}

EditState.propTypes = {
   title: PropTypes.string,
   icon: PropTypes.string,
   isTweet: PropTypes.bool,
   name: PropTypes.string,
   description: PropTypes.string,
   attachFile: PropTypes.bool,
   screenWidth: PropTypes.number,
   payload: PropTypes.object,
   checked: PropTypes.bool,
   onChangeData: PropTypes.func,
   initialPayload: PropTypes.object,
   onClickRevert: PropTypes.func,
   needToConnectTwiter: PropTypes.bool,
   onClickConnectTwitterAccount: PropTypes.func,
   fetchingConnectedTwitterAccount: PropTypes.bool,
   uploadFile: PropTypes.func,
   uploadImages: PropTypes.func,
   onClickRemoveMedia: PropTypes.func,
   uploadVideo: PropTypes.func,
   individualVariables: PropTypes.array,
   isEqualData: PropTypes.bool,
   isMobile: PropTypes.bool,
   oldData: PropTypes.object,
   editorRef: PropTypes.any,

   siteInfo: PropTypes.object,
   slug: PropTypes.string,
   checkPayload: PropTypes.func,
   errors: PropTypes.object,
   clearErrorState: PropTypes.func,
   isInsideModal: PropTypes.bool,
   isDemoMode: PropTypes.bool,
};

MoreInfoButton.propTypes = {
   text: PropTypes.string,
   onClick: PropTypes.func,
};
OutgoingNotificationsAndAutoTweetEdit.propTypes = {
   data: PropTypes.object,
   screenWidth: PropTypes.number,
   onSaveData: PropTypes.func,
   goBack: PropTypes.func,
   isFetching: PropTypes.bool,
   twitterAccount: PropTypes.object,
   onClickConnectTwitterAccount: PropTypes.func,
   fetchingConnectedTwitterAccount: PropTypes.bool,
   uploadFile: PropTypes.func,
   isNonContent: PropTypes.bool,
   eventName: PropTypes.string,
   individualVariables: PropTypes.array,
   siteInfo: PropTypes.object,
   errors: PropTypes.object,
   clearErrorState: PropTypes.func,
   isInsideModal: PropTypes.bool,
   insideModalCallBack: PropTypes.func,
   isDemoMode: PropTypes.bool,
};

export default React.memo(OutgoingNotificationsAndAutoTweetEdit);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary'
import Icon from 'common/components/elements/icons'
import MediaChip from 'common/components/modules/media-chip'
import { BlurhashCanvas } from "react-blurhash";
import { Link } from 'react-router-dom';
import IconLabel from 'common/components/elements/icon-label';
import cx from 'classnames';
import DateFormatter from 'common/utils/DateFormatter';
import moment from 'moment';
import PreviewVideo from 'sites/views/media-modules/preview-video';


const blurhash = require('blurhash');

const UnlockContentMessage = ({
   data,
   isAdmin,
   type,
   contentLink = () => {},
   onStream,
   closeChat,
   restFunction,
   isMobile,
   paymentButtonLoading,
   width = '250px',
   adminMessage,
   styles,
   message,
   countDown,
}) => {

   let isVideo = 'video_unlock' === type;
   let hash = null
   let price = +data.unlock_price;
   const isBlockForMember = !isAdmin && !(('is_unlocked_for_view' in data ? data?.is_unlocked_for_view : data?.is_unlocked) || !price)
   let style = {}
   let cover = '';
   if(!!data.cover) {
      cover = data.cover;
      if(!!data.cover_thumb) {
         cover = data.cover_thumb;
      }
      style = {
         ...style,
         backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0) 27.86%), url(${ cover }`,
         filter: 'blur(10px)',
      }
   }
   if(!cover && !isVideo) {
      if(data.photos_blurhash) {
         const { result } =  blurhash.isBlurhashValid(data.photos_blurhash)
         if(result) {
            hash = data.photos_blurhash
         } else {
            hash = 'UBF6UmB._3.S?^4TIARj00H?IUVsrX%#%Mxu'
         }
      } else {
         hash = 'UBF6UmB._3.S?^4TIARj00H?IUVsrX%#%Mxu'
      }

   }

   const getLiveStreamTooltiState = () => {
      let state = {}
      switch (data.access_type) {
         case 'unlocked':
            state.text = 'Has access';
            state.icon = 'video-camera';
            state.color = 'success';
            break;
         case 'locked':
            state.text = `${ price } USD to unlock`;
            state.icon = 'video-camera';
            state.color = 'pending';
            break;

         default:
            state.text = 'Cannot watch stream';
            state.icon = 'blocked-camera';
            state.color = 'secondary';
            break;
      }
      return state
   }

   let liveStreamTooltiState = null;
   const showMembersStreamTooltip = 'live_stream' === type && !isAdmin;
   if(showMembersStreamTooltip) {
      liveStreamTooltiState = getLiveStreamTooltiState();
   }

   // const unsendDate = DateFormatter.calculateTimeLeft((moment(message?.unsend_date, dateFormat)))
   const dateFormat = 'YYYY-MM-DD HH:mm:ss' //'YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]'
   const unsendDate = DateFormatter.calculateTimeLeft(DateFormatter.convertToUserTz((moment(message?.unsend_date, dateFormat).local())))  // (String(message?.unsend_date)?.endsWith('Z')) ?  DateFormatter.calculateTimeLeft(DateFormatter.convertToUtc(message?.unsend_date)) :
   const timeLeftToUnsend = DateFormatter.calculateTimeLeft(DateFormatter.convertToUtc((moment(Date.now()).add('minutes', message?.duration_in_minutes))))
   const previewSrc = data?.system_preview_video_full_src || data?.custom_preview_video_full_src
   // || 'https://staging-cdn.adultmembersites.com/videos/qysUR5k9P9AWgSmHg9800vHEK7NJaUEc/outputs/preview/system2.mp4?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6XC9cL3N0YWdpbmctY2RuLmFkdWx0bWVtYmVyc2l0ZXMuY29tXC92aWRlb3NcL3F5c1VSNWs5UDlBV2dTbUhnOTgwMHZIRUs3TkphVUVjXC9vdXRwdXRzXC9wcmV2aWV3XC9zeXN0ZW0yLm1wNCIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczOTAwMjY0OH19fV19&Signature=mb-P41PexIwjSx6bsX6mZr0K8hLqolF47FoB-7hb7FC~cJm30Y7DW04e1Pf0sVgygcXONtjrIct09C9Ptyj1GuU7oUB4NWj~j5Q-clJs1JdYmIf8m1UHmebM9DuF5DtkAu8fRvym4wwDDFP3x2U8rWI-e2RPyoG~PmBZ7Chw3HVIPsbHTZJYeVXgSfOjjUyAsNAYgC5GBuZxg-g4~mkOQbJcMk6lmIUNzOWelSC~XnKu17G9Xu0N8H3yk75TSNMnOR22ev2Sz0CDzYyV4g5g8BMxmPchn9HrqKvfoU43OdW~y0fTifkErSYsojfLYTOMGpP3pTWCPOyjwexgs89daQ__&Key-Pair-Id=KTUJLALS4K9CN'
   const [isAutoPlay, setIsAutoPlay] = useState(false);

   const canPlayPreview = () => {
      if(!isMobile) {
         return isAutoPlay;
      }
      return true
   }

   let view = (
      <div
         { ...restFunction }
         style={ {
            width: width,
            ...styles,
         } }
         onMouseEnter={ () => {
            if(!!previewSrc && !isMobile && /video/.test(data?.resource_type)){
               setIsAutoPlay(true)
            }
         } }
         onMouseLeave={ () => {
            if(!!previewSrc && !isMobile && /video/.test(data?.resource_type)){
               setIsAutoPlay(false)
            }
         } }
         id='media-layer'
         className={ `flex flex-col rounded-lg relative overflow-hidden aspect-ratio-padding z-1 group` }
      >
         {
            !!cover && (!isBlockForMember || (!isAdmin && !data.locked_poster_is_blur)) && (
               <img src={ cover } alt='' className='w-full h-full object-contain object-center absolute inset-0 z-1' />
            )
         }
         {
            !!previewSrc &&
            canPlayPreview() &&
            <div className='absolute top-0 left-0 w-full h-full'>
               <PreviewVideo
                  // showFullScreenButton
                  previewSrc={ previewSrc }
                  // showVolumeButton={ Boolean(data?.custom_preview_video_full_src) }
                  poster={ cover }
                  // voiceIconRightPosition={ 16 }
                  videoId={ data?.resource_id || data?.vaultId }
                  // isContentCard
                  // setOpenFullScreen={ _ => {
                  //    if(!!previewSrc && !isMobile){
                  //       setIsAutoPlay(false);
                  //    }
                  // } }
                  // openFullscreen={ openFullscreen }
               />
            </div>
         }
         <div
            style={ {
               ...style,
            } }
            className={ cx({
               'bg-cover bg-center bg-no-repeat w-full h-full absolute inset-0 z-0': true,
            }) }
         >
            {
               !!hash && (
                  <BlurhashCanvas
                     hash={ hash }
                     punch={ 1 }
                     className='h-full w-full'
                  />
               )
            }
         </div>
         {
            ((!countDown && !!message?.duration_in_minutes && !message?.unsend_date) || (unsendDate && message?.unsend_date)) && (isAdmin || (!isAdmin && !!message?.show_duration_countdown_to_member)) && <>
               <div
                  style={ {
                     backgroundColor: `rgba(4, 21, 39, 0.3)`,
                  } }
                  className={ cx({
                     'w-full h-full absolute inset-0 z-40 flex  justify-center **group-hover:hidden': true,
                     'items-start': isBlockForMember,
                     'items-center': !isBlockForMember,
                  }) }
               >
                  <div className={ cx({
                     'flex flex-col items-center justify-center gap-1': true,
                     'mt-3': isBlockForMember,
                  }) }>
                     <Icon
                        name={ `duration` }
                        color={ `major-dark` }
                        darkColor={ `major-dark` }
                        size={ `2xl` }
                     />
                     <p className={ cx({
                        'leading-7 font-semibold': true,
                        'text-major-dark dark:text-major-dark ': true,
                        'text-base': isBlockForMember,
                        'text-xl': !isBlockForMember,
                     }) }>
                        Message unsends in
                     </p>
                     <p className={ cx({
                        'leading-4 font-semibold': true,
                        'text-major-dark dark:text-major-dark ': true,
                        'text-sm': isBlockForMember,
                        'text-lg': !isBlockForMember,
                     }) }>
                        {
                           !!(!countDown && !!message?.duration_in_minutes && !message?.unsend_date) && timeLeftToUnsend
                        }
                        {
                           !(!countDown && !!message?.duration_in_minutes && !message?.unsend_date) && unsendDate
                        }
                     </p>
                     {
                        isAdmin && !countDown && !!message?.duration_in_minutes && !message?.unsend_date && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))) && <>
                           <div className='flex items-center gap-1 pb-2'>
                              <p className={ cx({
                                 'text-major-dark text-xs leading-[15px]': true,
                              }) }>
                                 Countdown starts when seen
                              </p>
                           </div>
                        </>
                     }
                  </div>
               </div>
            </>
         }
         {
            adminMessage && 'live_stream' !== type && (
               <MediaChip
                  type={ isVideo ? 'video' : 'photoset' }
                  className='m-1 z-2 absolute right-1 top-1'
                  countOrDuration={ isVideo ? data.video_duration : data.photos_count }
               />
            )
         }
         <div className={ `absolute w-full flex flex-col flex-1 h-full z-40 inset-0 ${ !!price && !isAdmin && !data?.allow_download  && !showMembersStreamTooltip ? ' justify-end' : 'justify-start' }` }>
            {
               (!!price || data?.allow_download) &&  (
                  <div
                     className='flex p-2'
                  >
                     {
                        isAdmin && !!price && (
                           <div
                              data-tooltip-content={ data.is_unlocked ? `Unlocked for ${ price } USD` : `${ price } USD to unlock` }
                              data-tooltip-id={ (isAdmin || showMembersStreamTooltip) ? 'ams-top-tooltip' : null }
                              className={ `flex h-6 w-6 items-center justify-center bg-panel dark:bg-panel-dark rounded-full z-2 ${ data?.allow_download ? 'mr-2' : '' }` }
                           >
                              <Icon
                                 color='major'
                                 darkcolor='major-dark'
                                 name={ !data.is_unlocked ? 'lock' : 'unlocked' }
                              />
                           </div>

                        )
                     }
                     {
                        data?.allow_download && (
                           <div
                              data-tooltip-content={ isBlockForMember ? 'Download after unlock' : 'Download allowed' }
                              data-tooltip-id='ams-top-tooltip'
                              className='flex h-6 w-6 items-center justify-center bg-panel dark:bg-panel-dark rounded-full z-2 cursor-pointer'
                           >
                              <Icon
                                 color='major'
                                 darkcolor='major-dark'
                                 name='download'
                              />
                           </div>
                        )
                     }

                  </div>
               )
            }
            {
               showMembersStreamTooltip && (
                  <IconLabel
                     data-tooltip-content={ liveStreamTooltiState.text }
                     data-tooltip-id={ (isAdmin || showMembersStreamTooltip) ? 'ams-top-tooltip' : null }
                     color={ liveStreamTooltiState?.color }
                     name={ liveStreamTooltiState?.icon }
                     className='h-6 w-6 m-2 z-2'
                     size='base'
                  />

               )
            }

            {
               isBlockForMember && !showMembersStreamTooltip && (
                  <div className='w-full pt-0 h-12 p-3 z-40 absolute bottom-0'>
                     <Button
                        text={ `Unlock for ${ price } USD` }
                        iconName={ isVideo ? 'play-default' : 'image' }
                        iconPosition='left'
                        classNames='h-full px-3'
                        padding='none'
                        textSize='base'
                        borderRadius='large'
                        onClick={ (e) => {
                           e.stopPropagation()
                           e.preventDefault()
                           onStream()
                        } }
                        isLoading={ paymentButtonLoading }
                     />
                  </div>
               )
            }
         </div>
      </div>
   )

   if(!!contentLink && contentLink()) {
      return (
         <Link
            onClick={ isAdmin ? null : () => {
               if(closeChat) {
                  closeChat()
               }

            } }
            to={ contentLink() } >
            {view}
         </Link>

      )
   }
   return (
      view
   );
};


UnlockContentMessage.propTypes = {
   data: PropTypes.object,
   isAdmin: PropTypes.bool,
   type: PropTypes.string,
   onStream: PropTypes.func,
   closeChat: PropTypes.func,
   paymentButtonLoading: PropTypes.bool,
   width: PropTypes.string,
   contentLink: PropTypes.any,
   restFunction: PropTypes.any,
   adminMessage: PropTypes.bool,
   styles: PropTypes.object,
   message: PropTypes.object,
   countDown: PropTypes.number,
   isMobile: PropTypes.bool,
};

export default UnlockContentMessage;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from 'react-router';
import { connect } from "react-redux";
import Auth from 'admin/utils/Auth';
import { siteDetailsInitOperation } from 'admin/state/modules/common/operations';
import { resetCommonDetails, updateLiveStreamCurrentSessionState, setMassMessageLimit } from 'admin/state/modules/common/actions';
import { authUserSelector, isSiteInitedSelector, siteInfoSelector, viewAsModalSelector, liveStreamCurrentSessionStateSelector, screenWidthSelector, massMessageLimitSelector } from 'admin/state/modules/common/selectors';
import { ivsSelector, currentDataSelector, initDataSelector } from 'admin/state/modules/livestream/selectors';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import { resetUploadsVideos } from 'admin/state/modules/uploads/actions';
import {
   wrongDownloadModalSelector,
} from 'admin/state/modules/resources/selectors';
import { setWrongDownloadButtonModal } from 'admin/state/modules/resources/actions';
import { push } from 'connected-react-router';
import Router from 'admin/routes/router';
import Layout from 'common/layout';
import PageHeaderContainer from 'admin/containers/modules/page-header';
import NavigationContainer from 'admin/containers/modules/navigation';
import ProgressBarContainer from 'admin/containers/modules/progress-bar';
import UploadBarContainer from 'admin/containers/modules/upload-bar';
import AdminAgreement from 'admin/views/auth/agreement';
import { acceptAgreement } from 'admin/api/AuthApi';

import Loader from "common/components/elements/loader/circle";
import ViewAsContainer from 'admin/containers/modules/view-as';
import QueryParams from 'admin/utils/QueryParams';
import { clearChatInfo } from 'admin/state/modules/chat/actions';
import NotifiactionCard from 'common/components/modules/live-stream/notification-card';
import { createPortal, flushSync } from 'react-dom';
import WrongDownloadModal from 'admin/views/media-modules/wrong-download-modal';
import RebrandingModal from 'common/components/modules/rebranding-modal';
import MassMessageLimitModal from 'admin/views/mass-message/limit-modal';
class Authenticated extends Component {
   static propTypes = {
      authUser: PropTypes.object,
      siteInfo: PropTypes.any,
      isSiteInited: PropTypes.bool.isRequired,
      component: PropTypes.any,
      init: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
      viewAsModal: PropTypes.object,
      loginAs: PropTypes.func,
      setViewAsModal: PropTypes.func,
      locationPath: PropTypes.string,
      location: PropTypes.object,
      goTo: PropTypes.func,
      liveStreamCurrentSessionState: PropTypes.object,
      ivs: PropTypes.object,
      currentData: PropTypes.object,
      initData: PropTypes.object,
      updateLiveStreamCurrentSessionState: PropTypes.func,
      wrongDownloadModal: PropTypes.object,
      closeWrongModal: PropTypes.func,
      screenWidth: PropTypes.number,
      massMessageLimit: PropTypes.object,
      setMassMessageLimit: PropTypes.func,
   };

   state = {
      screenWidth: document.documentElement.clientWidth,
      screenHeight: document.documentElement.clientHeight,
      showRebrandingModal: false,
   };

   componentDidMount() {
      const {
         init,
         goTo,
      } = this.props;
      window.addEventListener("resize", () => {
         const screenWidth = document.documentElement.clientWidth;
         const screenHeight = document.documentElement.clientHeight;
         this.setState({
            screenWidth,
            screenHeight,
         });
      });
      init();
      let backRout = QueryParams.getParam('go-back');

      const showRebrandingModal = QueryParams.getParam('showRebrandingModal');
      if(showRebrandingModal){
         this.setState({ showRebrandingModal: true })
      }
      const cannyCompanyID = QueryParams.getParam('cannyCompanyID');
      const cannyRedirect = QueryParams.getParam('cannyRedirect');
      if(backRout && !cannyCompanyID && !cannyRedirect) {
         return goTo(push(decodeURIComponent(backRout)));
      }
   }
   componentWillUnmount() {
      if(window.uploadsVideoSoket && window.uploadsVideoSoket.disconnect) {
         window.uploadsVideoSoket.disconnect();
      }
   }

   componentDidUpdate(prevProps) {
      const {
         authUser, isSiteInited, logout, locationPath, location,
      } = this.props;
      if(isSiteInited && !authUser) {
         logout(locationPath, location.search);
         return;
      }
      const { siteInfo: prevSiteInfo } = prevProps;
      const { siteInfo: currentSiteInfo } = this.props;
      if(prevSiteInfo &&
         prevSiteInfo.site_status &&
         currentSiteInfo &&
         currentSiteInfo.site_status
      ) {
         if(prevSiteInfo.site_status !== currentSiteInfo.site_status) {
            if(window.Intercom) {
               setTimeout(() => {
                  console.log(`%c INTERCOM update`, 'background: #F5F5F5; color: #D32F7E');
                  window.Intercom('update');
               }, 500)
            }
         }
      }
   }

   handleAcceptAgreement = () => {
      if(this.agreementCallInProgress) return;
      this.agreementCallInProgress = true;
      acceptAgreement().then(() => {
         window.location.reload();
      }).catch(() => {
         alert('Error. Please contact support.')
      });
   }

   onClosesRebrandingModal = () => {
      this.setState({ showRebrandingModal: false })
      QueryParams.removeQueryParam('showRebrandingModal')
   }
   closeLimitModal = () => {
      const { setMassMessageLimit } = this.props
      flushSync(() => {
         setMassMessageLimit({ openModal: false, callback: null, cancelCallback: null })
      })
   }
   render() {
      const {
         component: ChildComponent,
         authUser,
         siteInfo,
         isSiteInited,
         viewAsModal,
         location,
         liveStreamCurrentSessionState,
         locationPath,
         updateLiveStreamCurrentSessionState,
         ivs,
         currentData,
         initData,
         wrongDownloadModal,
         closeWrongModal,
         massMessageLimit,
         ...rest
      } = this.props;
      const {
         screenHeight,
         screenWidth,
         showRebrandingModal,
      } = this.state;
      let loginAsPage = QueryParams.getParsedSearchParams('login-as')
      if(loginAsPage && loginAsPage.loginas === 'started') {
         return (
            <Layout >
               <Loader />
            </Layout>
         )
      }
      const isHiddenHeaderAndNavigation = Router.route('SIGN_IN_WITH').match(locationPath)
      if(isSiteInited && authUser) {
         return (
            <Layout>
               <div
                  className='menu-left-side'
                  style={ {
                     zIndex: 2,
                  } }
               >
                  {
                     massMessageLimit?.openModal && (
                        <MassMessageLimitModal
                           isMobile={ screenWidth < 1025 }
                           onCloseModal={ () => {
                              if(massMessageLimit?.cancelCallback) {
                                 massMessageLimit?.cancelCallback()
                              }
                              this.closeLimitModal()
                           } }
                           onClick={ (cb) => {
                              massMessageLimit?.callback(cb)
                           } }
                           data={ massMessageLimit }
                        />
                     )
                  }
                  {
                     showRebrandingModal && (
                        <RebrandingModal
                           onCloseModal={ this.onClosesRebrandingModal }
                        />
                     )
                  }
                  {
                     !isHiddenHeaderAndNavigation && (
                        <Layout.Menu>
                           <NavigationContainer />
                        </Layout.Menu>
                     )
                  }
               </div>
               <div
                  className='w-full'
                  style={ {
                     zIndex: 1,
                  } }
               >
                  {
                     !isHiddenHeaderAndNavigation && (
                        <Layout.Header>
                           <PageHeaderContainer>
                              <ProgressBarContainer />
                              <UploadBarContainer />
                           </PageHeaderContainer>
                        </Layout.Header>
                     )
                  }
                  <Layout.Content>
                     {
                        !siteInfo.agreement && (
                           <AdminAgreement
                              width={ screenWidth > 500 ? '720px' : undefined }
                              height={ screenWidth > 500 ? screenHeight < 650 ? '80vh' : '624px' : '100%' }
                              onAcceptAgreement={ this.handleAcceptAgreement }
                              onDeclineAgreement={ () => {
                                 // Auth.logout();
                                 // setTimeout(() => {
                                 //    window.location.reload();
                                 // })
                              } }
                              isCloseButtonVisible={ false }
                              onClickContentPolicy={ () => {
                                 window.open('https://help.mymember.site/en/articles/3512988-content-policy&sa=D&ust=1585644698065000&usg=AFQjCNHxpZp5AwcxDH6oZXFGTf1SwgELRg')
                              } }
                           />
                        )
                     }
                     <Route
                        { ...rest }
                        render={ (matchProps) => {
                           return <ChildComponent { ...matchProps } />;
                        } }
                     />
                  </Layout.Content>
               </div>
               {
                  viewAsModal.isOpen && screenWidth < 701 && (
                     <ViewAsContainer />
                  )
               }
               {
                  createPortal(
                     <NotifiactionCard
                        data={ {
                           ...liveStreamCurrentSessionState.data,
                           is_live_stream_session_active: !!liveStreamCurrentSessionState.data,
                           stream_url: ivs?.playbackUrl,
                           session_start_time: currentData?.session_start_time || initData.session_start_time,
                           audio_and_video_type: currentData?.audio_and_video_type || initData.audio_and_video_type,
                        } }
                        isAdmin
                        onCloseAction={ _ => {
                           updateLiveStreamCurrentSessionState({ isShowBanner: false })
                        } }
                        link={ Router.route('LIVE_STREAM_STUDIO').getCompiledPath() }
                        show={ (liveStreamCurrentSessionState.isShowBanner && !Router.route('LIVE_STREAM_STUDIO').match(locationPath)) }
                     />,
                     document.getElementById('root')
                  )
               }
               {
                  wrongDownloadModal.isOpen && (
                     <WrongDownloadModal
                        header={ wrongDownloadModal.header }
                        body={ wrongDownloadModal.body }
                        closeModal={ closeWrongModal }
                     />
                  )
               }
            </Layout>
         );
      }
      return null;
   }
}

const mapStateToProps = (state) => {
   return {
      authUser: authUserSelector(state),
      screenWidth: screenWidthSelector(state),
      isSiteInited: isSiteInitedSelector(state),
      siteInfo: siteInfoSelector(state),
      viewAsModal: viewAsModalSelector(state),
      locationPath: currentLocationPathSelector(state),
      liveStreamCurrentSessionState: liveStreamCurrentSessionStateSelector(state),
      currentData: currentDataSelector(state),
      ivs: ivsSelector(state),
      initData: initDataSelector(state),
      wrongDownloadModal: wrongDownloadModalSelector(state),
      massMessageLimit: massMessageLimitSelector(state),
   };
}

const mapDispatchToProps = (dispatch) => {
   return {
      init: () => {
         dispatch(siteDetailsInitOperation())
      },
      logout: (path, search) => {
         Auth.logout();
         dispatch(resetCommonDetails());
         dispatch(resetUploadsVideos());
         dispatch(clearChatInfo());
         let existPath = path && path !== '/';
         let goBack = existPath ? `go-back=${ path }` : '';
         if(!!search) {
            goBack = `${ goBack }${ encodeURIComponent(search) }`
         }

         // If auth user dont exist redirect to sign in page with specific query param
         let page = ''
         if(window.location.pathname.includes(Router.route('SIGN_IN_WITH').getCompiledPath())) {
            page = 'sign-in'
            goBack = '?sign_in_referrer=mylist'
         }
         //

         Auth.redirectSignIn(goBack, () => {
            dispatch(push({
               pathname: Router.route('SIGN_IN').getCompiledPath(),
               search: goBack,
            }));
         }, page);
      },
      goTo: (path) => {
         dispatch(path);
      },
      updateLiveStreamCurrentSessionState: data => {
         dispatch(updateLiveStreamCurrentSessionState(data))
      },
      closeWrongModal: (data) => dispatch(setWrongDownloadButtonModal(data)),
      setMassMessageLimit: data => dispatch(setMassMessageLimit(data)),
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
